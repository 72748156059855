// Giga Design Studio - Mullet
// 00 - Variables
// Vars you may need to change for each project

// 01 - units, widths and spacings

@use "sass:math";

$basic__unit: 3px;
$type__unit: $basic__unit*4;
$border__unit: 1px;
$spacing__unit: $type__unit*2;
$grid__unit: 6;
$radius__unit: $type__unit*0.25;
$bold__unit: 600;
$light__unit: 400;

$one__full: 100%;
$one__half: 1*0.5 * 100%;
$one__third: math.div(1, 3) * 100%;
$one__fourth: 1*0.25 * 100%;
$one__fifth: 1*0.2 * 100%;

$padding--all: $spacing__unit;
$padding--horizontal: 0 $spacing__unit;
$padding--vertical: $spacing__unit 0;
$margin--center: 0 auto;


// 02 - fonts

$sans: 'Work Sans', sans-serif;
$ui: 'Poppins', serif;
$mono: 'Space Mono', monospace;


// 03 - media queries

$xxxxs: 320px; // small phones
$xxxs: 360px;
$xxs: 420px; // big phones
$xs: 600px;
$s: 740px; // ipad
$m: 1000px;
$l: 1240px; // desktop
$xl: 1420px;
$xxl: 1640px; // big desktop
$xxxl: 1880px;
$xxxxl: 2200px;


// 04 - colours

$rose: #FFCCC7;
$green: #C9FFDE;
$blue: #BAE3FA;
$violet: #EBD9FF;
$yellow: #FFFFC2;
$grey: #ABADB0;

$white: rgba(255,255,255,1);
$black: rgba(0,0,0,1);
$white__trans--xl: rgba(255, 255, 255, 0.1);
$white__trans--l: rgba(255, 255, 255, 0.3);
$white__trans: rgba(255, 255, 255, 0.5);
$white__trans--d: rgba(255, 255, 255, 0.7);
$white__trans--xd: rgba(255, 255, 255, 0.9);
$black__trans--xl: rgba(0, 0, 0, 0.1);
$black__trans--l: rgba(0, 0, 0, 0.3);
$black__trans: rgba(0, 0, 0, 0.5);
$black__trans--d: rgba(0, 0, 0, 0.7);
$black__trans--xd: rgba(0, 0, 0, 0.9);
$grey--xxl: rgba(245,245,245,1);
$grey--xl: rgba(235,235,235,1);
$grey--l: rgba(204,204,204,1);
$grey: rgba(153,153,153,1);
$grey--d: rgba(102,102,102,1);
$grey--d--trans: rgba(102,102,102,0.5);
$grey--xd: rgba(51,51,51,1);
$grey--xxd: rgba(25,25,25,1);
$glow--xs: 0 0 4px $black--trans--xl;
$glow--s: 0 0 8px $black--trans--xl;
$glow: 0 0 16px $black--trans--xl;
$glow--l: 0 0 36px $black--trans--xl;
$glow--xl: 0 0 72px $black--trans--xl;
$shadow--xs: 0 1px 4px $black--trans--xl;
$shadow--s: 0 2px 8px -1px $black--trans--xl;
$shadow: 0 4px 16px -2px $black--trans--xl;
$shadow--l: 0 8px 32px -4px $black--trans--xl;
$shadow--xl: 0 16px 64px -8px $black--trans--xl;

$border__color: $grey--xl;

$primary__color: rgba(255,80,65,1);
$primary__color--trans: rgba(255,80,65,0.5);
$primary__color--hover: rgba(230,20,0,1);
$primary__color--active: $black;

$secondary__color: rgba(10,80,200,1);
$secondary__color--trans: rgba(10,80,200,0.5);
$secondary__color--hover: rgba(0,50,150,1);
$secondary__color--active: $white;

$tertiary__color: rgba(255,200,0,1);
$tertiary__color--trans: rgba(255,200,0,0.5);
$tertiary__color--hover: rgba(255,150,0,1);
$tertiary__color--active: $black;

$basic__color: $white;
$basic__color--trans: $white__trans--d;
$basic__color--hover: $grey;
$basic__color--active: $grey--xxd;

$selection__color--bg: $grey--d;
$selection__color--txt: $black;


// 05 - time and easings

$ease__o--xxf: 0.15s ease-out;
$ease__o--xf: 0.2s ease-out;
$ease__o--f: 0.3s ease-out;
$ease__o: 0.6s ease-out;

$ease__i--xxf: 0.15s ease-in;
$ease__i--xf: 0.2s ease-in;
$ease__i--f: 0.3s ease-in;
$ease__i: 0.6s ease-in;

$ease__io--sine: 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
$ease__io--sine--f: 0.15s cubic-bezier(0.445, 0.05, 0.55, 0.95);
$ease__io--sine--s: 0.6s cubic-bezier(0.445, 0.05, 0.55, 0.95);

$ease__o--sine--s: 0.6s cubic-bezier(0.39, 0.575, 0.565, 1);
$ease__o--sine: 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
$ease__o--sine--f: 0.15s cubic-bezier(0.39, 0.575, 0.565, 1);

// Fonts

@font-face {
	font-family: 'Poppins';
	src: url('../assets/fonts/Poppins_400_normal.eot');
	src: local('☺'), url('../assets/fonts/Poppins_400_normal.woff2') format('woff2'), url('../assets/fonts/Poppins_400_normal.woff') format('woff'), url('../assets/fonts/Poppins_400_normal.ttf') format('truetype'), url('../assets/fonts/Poppins_400_normal.svg') format('svg');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Poppins';
	// src: url('../assets/fonts/Poppins_400_italic.eot');
	src: local('☺'), url('../assets/fonts/Poppins_400_italic.woff2') format('woff2'), url('../assets/fonts/Poppins_400_italic.woff') format('woff'), url('../assets/fonts/Poppins_400_italic.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Poppins';
	// src: url('../assets/fonts/Poppins_600_normal.eot');
	src: local('☺'), url('../assets/fonts/Poppins_600_normal.woff2') format('woff2'), url('../assets/fonts/Poppins_600_normal.woff') format('woff'), url('../assets/fonts/Poppins_600_normal.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Poppins';
	// src: url('../assets/fonts/Poppins_600_italic.eot');
	src: local('☺'), url('../assets/fonts/Poppins_600_italic.woff2') format('woff2'), url('../assets/fonts/Poppins_600_italic.woff') format('woff'), url('../assets/fonts/Poppins_600_italic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Work Sans';
	// src: url('../assets/fonts/Poppins_400_normal.eot');
	src: local('☺'), url('../assets/fonts/Work+Sans_200_normal.woff2') format('woff2'), url('../assets/fonts/Work+Sans_200_normal.woff') format('woff'), url('../assets/fonts/Poppins_400_normal.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}

// @font-face {
// 	font-family: 'Work Sans';
// 	src: url('../assets/fonts/Poppins_400_normal.eot');
// 	src: local('☺'), url('../assets/fonts/Poppins_400_normal.svg') format('svg');
// 	font-weight: 400;
// 	font-style: normal;
// }
@font-face {
	font-family: 'Work Sans';
	// src: url('../assets/fonts/Poppins_400_normal.eot');
	src: local('☺'), url('../assets/fonts/Work+Sans_600_normal.woff2') format('woff2'), url('../assets/fonts/Work+Sans_600_normal.woff') format('woff'), url('../assets/fonts/Poppins_400_normal.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}
