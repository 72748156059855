// Cursor

#app {
  //  cursor: none;

  a,
  .collapse__trigger,
  button {
    // cursor: none;
  }
}

.cursor__wrapper {
  //display: none;
}

.no-touchevents {
  .cursor__wrapper {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    pointer-events: none;
    left: -4px;
    top: -4px;
    z-index: 11000;

    .cursor {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      // background: rgba(150,70,245,1);
      background-color: $grey--d;
      position: relative;
      mix-blend-mode: difference;
      @include transition(all $ease__o--sine);
      @include transform-origin(center);

      &::after {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        text-align: center;
        font-size: 6px;
        line-height: 8px;
      }
    }

    &--pulse {
      .cursor {
        @include animation(pulse, 1.8s, infinite, cubic-bezier(0.39, 0.575, 0.565, 1));
      }
    }

    &--active {
      .cursor {
        @include transform(scale(6));
        background-color: $black--trans--l;
      }
    }

    &--active--small {
      .cursor {
        @include transform(scale(3));
        background-color: $black--trans--l;
      }
    }

    &--active--small--white {
      .cursor {
        @include transform(scale(3));
        background-color: $white--trans;
      }
    }

    &--white {
      .cursor {
        background-color: $white--trans;
      }
    }

    &--expand {
      .cursor {
        @include transform(scale(6));
        background-color: $black--trans--l;
      }
    }

    &--hidden {
      .cursor {
        visibility: hidden;
      }
    }
  }
}



// Initial animations

.section__branding {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;

  &--paused {
    @include opacity(0);
  }

  svg {
    opacity: 0;

    rect,
    g,
    polygon,
    circle,
    path {
      transform-origin: center;
      opacity: 0.99
    }

    &.svg--centered {
      // @include transition(opacity $ease__io--sine--f);
      width: 100vh;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;

      // opacity: 0;
      @media screen and (min-width: $m) {
        width: 100vw;
        height: 100vw;
      }

      &--hidden {
        @include animation(fadeOut, 0.6s, 1, cubic-bezier(0.39, 0.575, 0.565, 1), 0s, forwards);
        // visibility: hidden;
        // display: none;
      }

      &--visible {
        @include animation(fadeIn, 0.6s, 1, cubic-bezier(0.39, 0.575, 0.565, 1), 0.3s, forwards);
        // visibility: visible;
        // display: block;
      }
    }
  }
}

.svg--placeholder {
  width: 100%;
  height: 100%;
  @include background-cover;
}

.svg--placeholder-portrait {
  background-image: url('/assets/img/static-home-portrait.png')
}

.svg--placeholder-landscape {
  background-image: url('/assets/img/static-home-landscape.png')
}

:root {
  --initialTiming: 1250ms;
}

.first-animation {
  .header {
    .header__wrapper {
      &--desktop {
        opacity: 0;
        @include transform(translateY(-200%));
        @include animation(showDown, 0.6s, 1, cubic-bezier(0.445, 0.05, 0.55, 0.95), var(--initialTiming), forwards);
      }

      &--mobile {
        opacity: 0;
        @include transform(translateY(200%));
        @include animation(showUp, 0.6s, 1, cubic-bezier(0.445, 0.05, 0.55, 0.95), var(--initialTiming), forwards);
      }

      // &--live-program {
      //   opacity: 0;
      //   @include transform(translateY(200%));
      //   @include animation(showUp, 0.6s, 1, cubic-bezier(0.445, 0.05, 0.55, 0.95), var(--initialTiming), forwards);
      // }
    }
  }

  .section {
    &--intro {
      &+main {
        // @include animation(scroll, 0.3s, 1, cubic-bezier(0.445, 0.05, 0.55, 0.95), 2s, forwards);
      }
    }
  }
}

.no-first-animation {
  .section {
    &--intro {
      &+main {
        // @include animation(scroll, 0.3s, 1, cubic-bezier(0.445, 0.05, 0.55, 0.95), 0s, forwards);
      }
    }
  }
}

// Page transitions

.page-transition-leave-to {
  // transform: translateY(-5vh);
  @include opacity(0);
  // .section {
  //   transform: translateY(-5vh);
  //   @include opacity(0);
  // }
}

// .page-transition-enter
.page-transition-enter-to {
  // transform: translateY(5vh);
  @include opacity(0);
  // .section {
  //   transform: translateY(-5vh);
  //   @include opacity(0);
  // }
}

// .page-overlay-enter,
// .page-overlay-leave-to {
//   @include opacity(0);
// }
//
// .page-overlay-enter-to,
// .page-overlay-leave {
//   @include opacity(1);
// }

.transition-submenu-enter-active,
.transition-submenu-leave-active {
  @include transition(all $ease__io--sine);
}

.transition-submenu-enter,
.transition-submenu-leave-to {
  @media screen and (min-width: $m) {
    top: $spacing__unit !important;
    @include opacity(0);
  }
}

.transition-live-enter-active,
.transition-live-leave-active {
  @include transition(all $ease__o);
}

.transition-live-enter,
.transition-live-leave-to {
  @media screen and (min-width: $m) {
    top: $spacing__unit !important;
    @include opacity(0);
  }
}

.intro-transition-enter-active,
.intro-transition-leave-active {
  @include transition(all $ease__io--sine);
}

.intro-transition-enter,
.intro-transition-leave-to {
  @include opacity(0);
}

.transition-element {
  @include transform(translateY(100%));
  @include transition(transform $ease__io--sine--s);
  // @include transition-delay(0.15s);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  pointer-events: none;
  width: 100%;
  height: 100vh;
  will-change: transform;
  visibility: hidden;
  background: $rose; // Fallback

  &--rose {
    background: $rose;
  }

  &--green {
    background: $green;
  }

  &--blue {
    background: $blue;
  }

  &--violet {
    background: $violet;
  }

  &--yellow {
    background: $yellow;
  }

  &--page-leaving {
    @include transform(translateY(0%));
    visibility: visible;
  }

  &--page-entering {
    @include transform(translateY(-100%));
    visibility: visible;
  }
}

.live-program__icon {
  @include animation(steps, 1.5s, infinite, ease-in-out);
}

// .program-slug {
//   .transition-element {
//     display: none !important;
//   }
// }

@keyframes showHideMarquee {
  0% {
    @include opacity(0);
    @include transform(translateY(100%));
  }

  25% {
    @include opacity(1);
    @include transform(translateY(0));
  }

  75% {
    @include opacity(1);
    @include transform(translateY(0));
  }

  100% {
    @include opacity(0);
    @include transform(translateY(-100%));
  }
}

;

@keyframes showMarquee {
  0% {
    @include opacity(0);
    @include transform(translateY(100%));
  }

  100% {
    @include opacity(1);
    @include transform(translateY(0));
  }
}

;

@keyframes hideMarquee {
  0% {
    @include opacity(1);
    @include transform(translateY(0));
  }

  100% {
    @include opacity(0);
    @include transform(translateY(-100%));
  }
}

;