// Giga Design Studio - Mullet
// 01 - Functions
// Fast fnctns and crossbrowsing tingss

// 01 - general mixins

@use "sass:list";

@mixin opacity($opacity, $important: false) {
  opacity: $opacity;
  -moz-opacity: $opacity;
  -khtml-opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8

  @if($important) {
    opacity: $opacity !important;
    -moz-opacity: $opacity !important;
    -khtml-opacity: $opacity !important;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie) !important; //IE8
  }
}

@mixin filter($filter-type, $filter-amount) {
  -webkit-filter: $filter-type+unquote('(#{$filter-amount})');
  -moz-filter: $filter-type+unquote('(#{$filter-amount})');
  -ms-filter: $filter-type+unquote('(#{$filter-amount})');
  -o-filter: $filter-type+unquote('(#{$filter-amount})');
  filter: $filter-type+unquote('(#{$filter-amount})');
}

@mixin clip-path($clip-path) {
  clip-path: $clip-path;
  -webkit-clip-path: $clip-path;
}

@mixin appeareance ($appeareance) {
  appearance: $appeareance;
  -moz-appearance: $appeareance;
  -webkit-appearance: $appeareance;
}

@mixin kerning {
  -moz-font-feature-settings: "kern" 1;
  -ms-font-feature-settings: "kern" 1;
  -o-font-feature-settings: "kern" 1;
  -webkit-font-feature-settings: "kern" 1;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
}

@mixin userSelect($args...) {
  -webkit-touch-callout: $args;
  -webkit-user-select: $args;
  -khtml-user-select: $args;
  -moz-user-select: $args;
  -ms-user-select: $args;
  user-select: $args;
}

@mixin box-sizing($args...) {
  -webkit-box-sizing: $args;
  -moz-box-sizing: $args;
  box-sizing: $args;
}

@mixin box-shadow($args...) {
  -webkit-box-shadow: $args;
  -moz-box-shadow: $args;
  box-shadow: $args;
}

@mixin linear-gradient($direction, $color-stops...) {
  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}

@mixin textSizeAdjust($args...) {
  -webkit-text-size-adjust: $args;
  -moz-text-size-adjust: $args;
  -ms-text-size-adjust: $args;
}

@mixin selection {
  ::-moz-selection {
    @content;
  }

  ::selection {
    @content;
  }
}

@mixin column-count($args...) {
  -webkit-column-count: $args;
  -moz-column-count: $args;
  column-count: $args;
}

@mixin column-gap($args...) {
  -webkit-column-gap: $args;
  -moz-column-gap: $args;
  column-gap: $args;
}

@mixin placeholder($selector) {
  #{$selector}::-webkit-input-placeholder {
    @content;
  }

  :-moz-placeholder {
    @content;
  }

  #{$selector}::-moz-placeholder {
    @content;
  }

  :-ms-input-placeholder {
    @content;
  }
}

// 02 - transform, transitions and animations mixins

@mixin transform($args...) {
  -webkit-transform: $args;
  -moz-transform: $args;
  -ms-transform: $args;
  -o-transform: $args;
  transform: $args;
}

@mixin transform-origin($args...) {
  -webkit-transform-origin: $args;
  -moz-transform-origin: $args;
  -ms-transform-origin: $args;
  -o-transform-origin: $args;
  transform-origin: $args;
}

@mixin transition-delay($args...) {
  -webkit-transition-delay: $args;
  -moz-transition-delay: $args;
  -ms-transition-delay: $args;
  -o-transition-delay: $args;
  transition-delay: $args;
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }

  @-moz-keyframes #{$animationName} {
    @content;
  }

  @-o-keyframes #{$animationName} {
    @content;
  }

  @keyframes #{$animationName} {
    @content;
  }
}

@mixin animation($name, $duration, $iteration, $easing, $delay: 0s, $fill-mode: none, $direction: normal) {
  -webkit-animation-name: $name;
  -moz-animation-name: $name;
  -o-animation-name: $name;
  animation-name: $name;
  -webkit-animation-duration: $duration;
  -moz-animation-duration: $duration;
  -o-animation-duration: $duration;
  animation-duration: $duration;
  -webkit-animation-iteration-count: $iteration;
  -moz-animation-iteration-count: $iteration;
  -o-animation-iteration-count: $iteration;
  animation-iteration-count: $iteration;
  -webkit-animation-timing-function: $easing;
  -moz-animation-timing-function: $easing;
  -o-animation-timing-function: $easing;
  animation-timing-function: $easing;
  -webkit-animation-delay: $delay;
  -moz-animation-delay: $delay;
  -o-animation-delay: $delay;
  animation-delay: $delay;
  -webkit-animation-fill-mode: $fill-mode;
  -moz-animation-fill-mode: $fill-mode;
  -o-animation-fill-mode: $fill-mode;
  animation-fill-mode: $fill-mode;
  -webkit-animation-direction: $direction;
  -moz-animation-direction: $direction;
  -o-animation-direction: $direction;
  animation-direction: $direction;
}

@mixin animation-direction($direction) {
  -webkit-animation-direction: $direction;
  -moz-animation-direction: $direction;
  -o-animation-direction: $direction;
  animation-direction: $direction;
}

@mixin animation-name($name) {
  -webkit-animation-name: $name;
  -moz-animation-name: $name;
  -o-animation-name: $name;
  animation-name: $name;
}

// 03 - flexbox

@mixin flexbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

@mixin inline-flex {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

@mixin flex-direction($value: row) {
  @if $value ==row-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal;
  }

  @else if $value ==column {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
  }

  @else if $value ==column-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: vertical;
  }

  @else {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
  }

  -webkit-flex-direction: $value;
  -moz-flex-direction: $value;
  -ms-flex-direction: $value;
  flex-direction: $value;
}

@mixin flex-wrap($value: nowrap) {
  -webkit-flex-wrap: $value;
  -moz-flex-wrap: $value;

  @if $value ==nowrap {
    -ms-flex-wrap: none;
  }

  @else {
    -ms-flex-wrap: $value;
  }

  flex-wrap: $value;
}

@mixin flex-flow($values: (row nowrap)) {
  -webkit-flex-flow: $values;
  -moz-flex-flow: $values;
  -ms-flex-flow: $values;
  flex-flow: $values;
}

@mixin order($int: 0) {
  -webkit-box-ordinal-group: $int + 1;
  -webkit-order: $int;
  -moz-order: $int;
  -ms-flex-order: $int;
  order: $int;
}

@mixin flex-grow($int: 0) {
  -webkit-box-flex: $int;
  -webkit-flex-grow: $int;
  -moz-flex-grow: $int;
  -ms-flex-positive: $int;
  flex-grow: $int;
}

@mixin flex-shrink($int: 1) {
  -webkit-flex-shrink: $int;
  -moz-flex-shrink: $int;
  -ms-flex-negative: $int;
  flex-shrink: $int;
}

@mixin flex-basis($value: auto) {
  -webkit-flex-basis: $value;
  -moz-flex-basis: $value;
  -ms-flex-preferred-size: $value;
  flex-basis: $value;
}

@mixin flex($fg: 1, $fs: null, $fb: null) {
  // Set a variable to be used by box-flex properties
  $fg-boxflex: $fg;

  // Box-Flex only supports a flex-grow value so let's grab the
  // first item in the list and just return that.
  @if type-of($fg)=='list' {
    $fg-boxflex: nth($fg, 1);
  }

  -webkit-box-flex: $fg-boxflex;
  -webkit-flex: $fg $fs $fb;
  -moz-box-flex: $fg-boxflex;
  -moz-flex: $fg $fs $fb;
  -ms-flex: $fg $fs $fb;
  flex: $fg $fs $fb;
}

@mixin justify-content($value: flex-start) {
  @if $value ==flex-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
  }

  @else if $value ==flex-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
  }

  @else if $value ==space-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
  }

  @else if $value ==space-around {
    -ms-flex-pack: distribute;
  }

  @else {
    -webkit-box-pack: $value;
    -ms-flex-pack: $value;
  }

  -webkit-justify-content: $value;
  -moz-justify-content: $value;
  justify-content: $value;
}

@mixin align-items($value: stretch) {
  @if $value ==flex-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
  }

  @else if $value ==flex-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
  }

  @else {
    -webkit-box-align: $value;
    -ms-flex-align: $value;
  }

  -webkit-align-items: $value;
  -moz-align-items: $value;
  align-items: $value;
}

@mixin align-self($value: auto) {
  -webkit-align-self: $value;
  -moz-align-self: $value;

  @if $value ==flex-start {
    -ms-flex-item-align: start;
  }

  @else if $value ==flex-end {
    -ms-flex-item-align: end;
  }

  @else {
    -ms-flex-item-align: $value;
  }

  align-self: $value;
}

@mixin align-content($value: stretch) {
  -webkit-align-content: $value;
  -moz-align-content: $value;

  @if $value ==flex-start {
    -ms-flex-line-pack: start;
  }

  @else if $value ==flex-end {
    -ms-flex-line-pack: end;
  }

  @else {
    -ms-flex-line-pack: $value;
  }

  align-content: $value;
}

// 04 - grid layout

@mixin grid {
  display: -ms-grid;
  display: grid;
}

@mixin grid-gap ($value) {
  grid-gap: $value;
}

@mixin grid-template-columns ($value) {
  -ms-grid-columns: $value;
  grid-template-columns: $value;
}

@mixin grid-template-rows ($value) {
  -ms-grid-rows: $value;
  grid-template-rows: $value;
}

@mixin grid-child ($col-start, $col-end, $row-start, $row-end) {
  -ms-grid-column: $col-start;
  -ms-grid-column-span: $col-end - $col-start;
  -ms-grid-row: $row-start;
  -ms-grid-row-span: $row-end - $row-start;
  grid-column: list.slash($col-start, $col-end);
  grid-row: list.slash($row-start, $row-end);
}

// 05 - background images

$image-path: '/assets/img' !default;
$fallback-extension: 'png' !default;
$retina-suffix: '@2x';

@mixin background-image($name, $size: false) {
  background-image: url(#{$image-path}/#{$name}.#{$fallback-extension});
  background-image: url(#{$image-path}/#{$name}.svg);

  @if($size) {
    background-size: $size;
  }

  & {
    //@media only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3/2), only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
    //  background-image: url(#{$image-path}/#{$name}#{$retina-suffix}.#{$fallback-extension});
    //}
  }
}

@mixin background-cover {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@mixin background-contain {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

@mixin absolute-center($transform: false) {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;

  @if ($transform ) {
    @include transform(translateX(-50%) translateY(-50%));
  }
}

// 06 - other functions

@mixin underline($color) {
  background: linear-gradient(#fff, #fff) 0 90%/.05em 1px no-repeat, linear-gradient(#fff, #fff) 100% 90%/.05em 1px no-repeat, linear-gradient($color, $color) 0 90%/1px 1px repeat-x;
  text-shadow: .03em 0 #fff, -.03em 0 #fff, 0 .03em #fff, 0 -.03em #fff, .06em 0 #fff, -.06em 0 #fff, .09em 0 #fff, -.09em 0 #fff, .12em 0 #fff, -.12em 0 #fff, .15em 0 #fff, -.15em 0 #fff;
}

@mixin overflow-iphone {
  -webkit-overflow-scrolling: touch;
}

@mixin reset-form-element {
  margin: 0;
  border: 0;
  padding: 0;
  display: block;
  vertical-align: middle;
  white-space: normal;
  background: none;
  line-height: 1;
  @include appeareance(none);
}

@function calculatePx($size) {
  $pxSize: $size * 10px;
  @return $pxSize;
}

@mixin remSize($selector, $size) {
  #{$selector}: calculatePx($size);
  #{$selector}: $size * 1rem;
}

@mixin rem($selector, $size, $important: false) {
  @if($important) {
    #{$selector}: calculatePx($size) !important;
    #{$selector}: $size * 1rem !important;
  }

  @else {
    #{$selector}: calculatePx($size);
    #{$selector}: $size * 1rem;
  }
}

@mixin clear-after() {
  content: ' ';
  display: block;
  clear: both;
}

@mixin color($selector, $r, $g, $b, $a) {
  #{$selector}: rgb($r, $g, $b);
  #{$selector}: rgba($r, $g, $b, $a);
}

@mixin calc($selector, $v1, $v2) {
  #{$selector}: -moz-calc(#{$v1} + #{$v2});
  #{$selector}: -webkit-calc(#{$v1} + #{$v2});
  #{$selector}: -o-calc(#{$v1} + #{$v2});
  #{$selector}: calc(#{$v1} + #{$v2});
}

@mixin calcNegative($selector, $v1, $v2) {
  #{$selector}: -moz-calc(#{$v1} - #{$v2});
  #{$selector}: -webkit-calc(#{$v1} - #{$v2});
  #{$selector}: -o-calc(#{$v1} - #{$v2});
  #{$selector}: calc(#{$v1} - #{$v2});
}

@mixin calcRem($selector, $v1, $v2) {
  #{$selector}: $v1;
  #{$selector}: -moz-calc(#{$v1} + #{calculatePx($v2)});
  #{$selector}: -moz-calc(#{$v1} + #{$v2}rem);
  #{$selector}: -webkit-calc(#{$v1} + #{calculatePx($v2)});
  #{$selector}: -webkit-calc(#{$v1} + #{$v2}rem);
  #{$selector}: -o-calc(#{$v1} + #{calculatePx($v2)});
  #{$selector}: -o-calc(#{$v1} + #{$v2}rem);
  #{$selector}: calc(#{$v1} + #{calculatePx($v2)});
  #{$selector}: calc(#{$v1} + #{$v2}rem);
}

@mixin calcRemNegative($selector, $v1, $v2, $important: false) {
  #{$selector}: $v1;
  #{$selector}: -moz-calc(#{$v1} - #{$v2}rem);
  #{$selector}: -webkit-calc(#{$v1} - #{$v2}rem);
  #{$selector}: -o-calc(#{$v1} - #{$v2}rem);
  #{$selector}: calc(#{$v1} - #{$v2}rem);

  @if($important) {
    #{$selector}: $v1 !important;
    #{$selector}: -moz-calc(#{$v1} - #{$v2}rem) !important;
    #{$selector}: -webkit-calc(#{$v1} - #{$v2}rem) !important;
    #{$selector}: -o-calc(#{$v1} - #{$v2}rem) !important;
    #{$selector}: calc(#{$v1} - #{$v2}rem) !important;
  }
}

@mixin hover($selector, $v1, $child: null, $v2: false) {
  @media not all and (hover: none) {
    &:hover {
      @if $child {
        #{$child} {
          @if $selector ==transform {
            @include transform($v1);
          }

          @else if $selector ==opacity {
            @include opacity($v1);
          }

          @else if $selector ==box-shadow {
            @include box-shadow($v1);
          }

          @else if $selector ==filter {
            @include filter($v1, $v2);
          }

          @else {
            #{$selector}: $v1 !important;
          }
        }
      }

      @else {
        @if $selector ==transform {
          @include transform($v1);
        }

        @else if $selector ==opacity {
          @include opacity($v1);
        }

        @else if $selector ==box-shadow {
          @include box-shadow($v1);
        }

        @else if $selector ==filter {
          @include filter($v1, $v2);
        }

        @else {
          #{$selector}: $v1;
        }
      }
    }
  }
}