// Giga Design Studio - Mullet
// 02 - Reset
// Reset things once for all

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

h1, h2, h3, h4, h5, h6, p, span, div, li, blockquote, pre, a {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* HTML5 display-role reset for older browsers */

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ul {
  list-style: none;
}

ol > li > ol {
  list-style-type: lower-alpha;
}

blockquote, q {
  quotes: none;
}

blockquote {
  &:before, &:after {
    content: '';
    content: none;
  }
}

q {
  &:before, &:after {
    content: '';
    content: none;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  width: 100%;
  height: 100%;
}

main {
  display: block;
}

dfn,
cite,
em,
i {
  font-style: italic;
}

address {
  font-style: italic;
  margin: 0 0 1.6em;
}

abbr[title] {
  border-bottom: 1px dotted #eaeaea;
  border-bottom: 1px dotted rgba(51, 51, 51, 0.1);
  cursor: help;
}

mark,
ins {
  background-color: #fff9c0;
  text-decoration: none;
}

sup,
sub {
  font-size: 75%;
  height: 0;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  bottom: 1ex;
}

sub {
  top: .5ex;
}

small {
  font-size: 75%;
}

big {
  font-size: 125%;
}

a {
  text-decoration: none;
}

dd {
 padding-left: 2em;
}

* {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

figure {
  text-align: center;
}

img {
  max-width: 100%;
  height: auto;
}

hr {
  border: 0;
  border-bottom: 1px solid;
  margin: 0;
  padding: 0;
}

b,
strong {
  font-weight: $bold__unit;
}

*:focus {
  outline: 0;
}

@-ms-viewport {
  width: device-width;
}

@viewport {
  width: device-width;
}

@media print {
  * {
    color: black !important;
    font-size: 75%;
    @include box-shadow(none !important);
  }
}
