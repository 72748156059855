@use "sass:math";

:root {
  --menuHeight: 0px;
  --marqueeSpeed: 30s;
  --marqueeVerticalSpeed: 3000ms;
  --messageBlastHeight: 36px;
}

.header {
  &--open {
    .header__wrapper {
      &--menu {
        @include transform(translateY(0));
        opacity: 1;
        @media screen and (min-width: $m) {
          display: none;
        }
      }
    }
    .nav__trigger {
      @include background-image('close');
    }
  }
  &--anchors {
    .header__wrapper {
      &--mobile {
        bottom: $spacing__unit*2.75;
      }
      &--desktop {
        border-radius: $radius__unit*0 $radius__unit*0 0 0;
      }
      &--menu {
        bottom: $spacing__unit*5.25;
      }
    }
  }
  .header--fixed-message {
    margin-top: var(--messageBlastHeight);
  }
}

.header__wrapper {
  @include transition(all $ease__io--sine);
  @include transition-delay(0.2s);
  position: fixed;
  max-width: 1680px;
  margin: 0 auto;
  background: $white;
  border-radius: 0;
  box-shadow: 0 0 18px -1px rgba(0,0,0,0.1);
  @media screen and (min-width: $m) {
    box-shadow: 0 0 18px -6px rgba(0,0,0,0.15);
  }
  &--mobile {
    @extend %type--xxs;
    @include flexbox;
    @include justify-content(space-between);
    z-index: 10;
    bottom: $spacing__unit*0.75;
    left: $spacing__unit*0.75;
    right: $spacing__unit*0.75;
    padding: 0 $spacing__unit*0.75;
    @media screen and (min-width: $m) {
      display: none;
    }
  }
  &--menu {
    @extend %type--s;
    // @include transform(translateY(150%));
    height: var(--menuHeight);
    opacity: 1;
    z-index: 9;
    bottom: $spacing__unit*3.25;
    left: $spacing__unit*0.75;
    right: $spacing__unit*0.75;
    border-radius: $radius__unit*0 $radius__unit*0 0 0;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: $bold__unit;
    text-align: center;
    overflow: hidden;
    nav {
      padding: $spacing__unit*2 $spacing__unit*0.75;
    }
    li {
      padding: $spacing__unit*0.25 0;
    }
    a {
      &.nuxt-link-active {
        opacity: 0.5;
      }
    }
  }
  &--desktop {
    @extend %type--s;
    @include justify-content(space-between);
    z-index: 10;
    bottom: auto;
    top: $spacing__unit;
    left: $spacing__unit;
    right: $spacing__unit;
    padding: 0 $spacing__unit;
    display: none;
    @media screen and (min-width: $m) {
      @include flexbox;
    }
    ul {
      a {
        @include hover(color, $black);
        &.nuxt-link-active {
          opacity: 0.5;
        }
      }
    }
  }
  &--eventnav {
    @extend %type--xxs;
    z-index: 9;
    left: $spacing__unit*0.75;
    right: $spacing__unit*0.75;
    bottom: $spacing__unit*0.75;
    border-radius: 0;
    @media screen and (min-width: $m) {
      bottom: auto;
      left: $spacing__unit;
      right: $spacing__unit;
      top: $spacing__unit*3.5;
    }
  }
  &--subnav {
    @extend %type--xxs;
    z-index: 9;
    left: $spacing__unit*0.75;
    right: $spacing__unit*0.75;
    bottom: $spacing__unit*0.75;
    border-radius: 0;
    @media screen and (min-width: $m) {
      bottom: auto;
      left: $spacing__unit;
      right: $spacing__unit;
      top: $spacing__unit*3.5;
    }
  }
  &--filters {
    @extend %type--xxs;
    z-index: 4;
    top: $spacing__unit*0.75;
    left: $spacing__unit*0.75;
    right: $spacing__unit*0.75;
    @include transition(all $ease__io--sine);
    @include transform(translateY(-200%));
    @media screen and (min-width: $m) {
      top: auto;
      left: $spacing__unit;
      right: $spacing__unit;
      bottom: $spacing__unit;
      @include transform(translateY(200%));
    }
  }
  &--filters-visible {
    @include transform(none);
    @media screen and (min-width: $m) {
      @include transform(none);
    }
  }
  &--live-program {
    z-index: 6;
    top: auto;
    bottom: $spacing__unit*3.25;
    left: $spacing__unit*0.75;
    right: $spacing__unit*0.75;
    padding: 0 $spacing__unit;
    border-radius: 0;
    @include transform(translateY(200%));
    max-height: $spacing__unit*3.25;
    overflow: hidden;
    @include transition(all $ease__io--sine--f);
    @media screen and (min-width: $m) {
      top: auto;
      bottom: $spacing__unit;
      left: $spacing__unit;
      right: $spacing__unit;
    }
    &--with-submenu {
      bottom: $spacing__unit*5.25;
      @media screen and (min-width: $m) {
        bottom: $spacing__unit;
      }
    }
    &--visible {
      @include transform(translateY(0));
    }
  }
}

.header__nav {
  min-height: 48px;
  &--left,
  &--right {
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: $bold__unit;
    @media screen and (min-width: $m) {
      width: 372px;
    }
    @media screen and (min-width: $l) {
      width: 454px;
    }
    ul {
      @media screen and (min-width: $m) {
        @include flexbox;
        @include justify-content(flex-end);
        padding: $spacing__unit*0.75 0;
      }
    }
  }
  &--left {
    @media screen and (min-width: $m) {
      @include flexbox;
    }
    li {
      @media screen and (min-width: $m) {
        margin-right: $spacing__unit*0.75;
      }
      @media screen and (min-width: $l) {
        margin-right: $spacing__unit*1.5;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &--right {
    li {
      @media screen and (min-width: $m) {
        margin-left: $spacing__unit*0.75;
      }
      @media screen and (min-width: $l) {
        margin-left: $spacing__unit*1.5;
      }
      &:first-child {
        margin-left: 0;
      }
    }
  }
  &--center {
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: $spacing__unit*0.75 0;
  }
  &--event {
    padding: 0 $spacing__unit;
    @include flexbox;
    @include justify-content(space-between);
    a {
      display: block;
      margin: 9px 0;
      width: 30px;
      height: 30px;
      @include background-contain;
    }
    .event__navigation {
      @include flexbox;
      @include justify-content(space-between);
      width: $spacing__unit*3;
      li {
        &:first-child {
          a {
            @include background-image('arrow_left');
          }
        }
        &:last-child {
          a {
            @include background-image('arrow_right');
          }
        }
      }
    }
    .event__close {
      a {
        @include background-image('close');
      }
    }
  }
  &--subnav,
  &--filters {
    ul {
      @include flexbox;
      padding: $spacing__unit*0.5 0;
      max-width: 300vw;
      overflow-x: scroll;
      @include overflow-iphone();
      scroll-behavior: smooth;
      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: $spacing__unit*1.25;
      }
      &::before {
        left: 0;
        border-radius: 0;
        @include linear-gradient(90deg, $white, rgba(255,255,255,0))
      }
      &::after {
        right: 0;
        border-radius: 0;
        @include linear-gradient(-90deg, $white, rgba(255,255,255,0))
      }
      &::-webkit-scrollbar {
        display: none;
      }
      @media screen and (min-width: $s) {
        @include justify-content(center);
        max-width: 100%;
      }
    }
    li {
      white-space: nowrap;
      padding: 0 $spacing__unit*0.5;
      &:first-child,
      &.nav__label {
        padding-left: $spacing__unit
      }
      &:last-child {
        padding-right: $spacing__unit
      }
      &.nav__label {
        color: $grey;
        @media screen and (min-width: $s) {
          position: absolute;
          left: 0;
        }
      }
      &.filter {
        text-transform: uppercase;
        font-weight: $bold__unit;
        border-radius: $spacing__unit*0.5;
        margin-right: $spacing__unit*0.5;
        a {
          color: $black;
        }
        &--clear {
          margin-right: 0;
          padding: 0;
          border-radius: 0;
        }
        &--conference {
          background: $yellow;
        }
        &--workshop {
          background: $violet;
        }
        &--satellite {
          background: $grey--xl;
        }
        &--other {
          background: $grey;
          a {
            color: $white;
          }
        }
        &--not-active {
          background: transparent !important;
          a {
            color: $grey;
          }
        }
      }
    }
    a {
      color: $grey;
      &.current {
        color: $black;
      }
    }
  }
}

.nav__logo {
  margin: 16px 0;
  width: 36px;
  height: 28px;
  @include background-image('logonumber');
  @include background-contain;
  a {
    display: block;
    width: 100%;
    height: 100%;
  }
  &--desktop {
    margin-right: 30px;
  }
}

.nav__trigger {
  margin: 12px 0;
  width: 36px;
  height: 36px;
  @include background-image('menu');
  @include background-contain;
}

// footer

.footer {
  padding-top: $spacing__unit*2;
  background: $white;
  &--anchors {
    .footer__colophon {
      padding-bottom: $spacing__unit*6.25;
      @media screen and (min-width: $m) {
        padding-bottom: $spacing__unit*1;
      }
    }
  }
  &--live-program {
    .footer__wrapper--black {
      padding-bottom: $spacing__unit*3.5;
      @media screen and (min-width: $m) {
        padding-bottom: $spacing__unit*4.25;
      }
    }
  }
  .section__logos {
    margin-top: 0;
    @include filter(grayscale, 1);
    img {
      max-width: 180px;
    }
  }
}

.footer__wrapper {
  width: 100%;
  background: $grey--xxl;
  &--black {
    background-color: $grey--xxd;
  }
  &--white {
    background-color: $white;
  }
}

.logos__header {
  @include grid;
  @extend %grid-gap;
  @include grid-template-columns(1fr);
  @extend %section-wrapper;
  text-transform: uppercase;
  padding-bottom: $spacing__unit*0.5;
  padding-top: $spacing__unit*1;
  @media screen and (min-width: $s) {
    padding-bottom: $spacing__unit*1;
    padding-top: $spacing__unit*2;
  }
}

.footer__main {
  @include grid;
  @extend %grid-gap;
  @include grid-template-columns(1fr);
  @extend %section-wrapper;
  padding-bottom: $spacing__unit*1;
  padding-top: $spacing__unit*1;
  // box-shadow: 0 -12px 18px -18px rgba(0,0,0,0.15);
  @media screen and (min-width: $xxs) {
    @include grid-template-columns(1fr 1fr);
  }
  @media screen and (min-width: $s) {
    padding-bottom: $spacing__unit*2;
    padding-top: $spacing__unit*2;
  }
  @media screen and (min-width: $l) {
    @include grid-template-columns(1fr 1fr 1fr 1fr);
  }
  .footer__branding {
    padding-top: $spacing__unit*0.25;
    .footer__logo {
      @include background-contain;
      @include background-image('logo--full');
      display: block;
      width: $spacing__unit*3.75;
      height: $spacing__unit*3.75;
    }
  }
  .footer__menu {
    a {
      @include hover(color, $black);
      &.nuxt-link-active {
        opacity: 0.5;
      }
    }
    h4 {
      font-weight: $bold__unit;
    }
    &--main {
      a {
        font-weight: $bold__unit;
      }
    }
  }
}

.footer__colophon {
  @extend %section-wrapper;
  padding-top: $spacing__unit*1;
  padding-bottom: $spacing__unit*4.25;
  // background-color: $grey--xxd;
  @media screen and (min-width: $m) {
    padding-bottom: $spacing__unit*1;
  }
  nav {
    ul {
      @include flexbox;
      @include flex-wrap(wrap);
      @include justify-content(center);
    }
    li {
      padding: 0 $spacing__unit*0.5;
    }
    a {
      @extend %type--xxs;
      @include hover(color, $basic__color--hover);
      display: block;
      color: $white;
    }
  }
  .footer__association {
    padding-top: $spacing__unit*1;
    .association__logo {
      @include background-image('logo--ixda');
      @include background-contain;
      width: 100%;
      display: block;
      height: $spacing__unit*1.5;
      width: $spacing__unit*5;
      margin: 0 auto;
    }
  }
  .footer__copyright {
    @extend %type--xxs;
    color: $grey;
    padding-top: $spacing__unit*1;
    text-align: center;
  }
}

// Live Program

.live-program {
  .calendar__venue,
  .calendar__header h3 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .calendar__header {
    a {
      max-width: none;
    }
  }
}

.live-program__header,
.live-program__toggle {
  margin: $spacing__unit 0;
}

.live-program__header {
  @include flexbox;
  margin-right: $spacing__unit;
  .live-program__title {
    margin-left: $spacing__unit*0.5;
    text-transform: uppercase;
    font-weight: $bold__unit;
    letter-spacing: 1px;
  }
  .live-program__icon {
    margin-top: 7px;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background: rgb(150, 250, 50);
    // background: red;
  }
}

.live-program__toggle {
  margin-left: $spacing__unit;
  a {
    @include background-cover;
    display: block;
    width: 30px;
    height: 30px;
    &.toggle--up {
      @include background-image('up');
    }
    &.toggle--down {
      @include background-image('up');
      @include transform(rotate(180deg));
    }
    &.toggle--close {
      @include background-image('close');
    }
  }
}

.live-program__wrapper {
  @include flexbox;
  @include justify-content(space-between);
}

.live-program__container {
  overflow-y: auto;
  overflow-x: hidden;
  @include overflow-iphone();
  @include opacity(0);
  @include transition(all $ease__io--sine--f);
  max-height: calc(100vh - 174px);
  @media screen and (min-width: $m) {
    max-height: calc(100vh - 208px);
  }
}

.live-program--open {
  .live-program__container {
    @include opacity(1);
  }
}

.live-program__content {
  &--current {
    padding-bottom: $spacing__unit;
    + .live-program__content--future {
      border-top: 1px solid $grey--xl;
    }
  }
  &--future {
    padding: $spacing__unit 0;
  }
  .calendar__item {
    padding: 0;
    border-bottom: none;
    margin-top: math.div($spacing__unit, 3);
    &:first-child {
      margin-top: 0;
    }
  }
}

.live-program__label {
  text-transform: uppercase;
  font-weight: $bold__unit;
  margin-bottom: $spacing__unit;
  letter-spacing: 1px;
}

.live-program__marquee {
  overflow: hidden;
  padding: $spacing__unit 0;
  @include opacity(1);
  @include transition(all $ease__io--sine--f);
  &--hidden {
    @include opacity(0);
  }
  .calendar__item {
    padding: 0;
    border-bottom: none;
  }
}

// Marquee

@keyframes marquee-rtl {
  0% {
    @include transform(translateX(0));
  }
  100% {
    @include transform(translateX(-50%));
  }
}

@keyframes marquee-ltr {
  0% {
    @include transform(translateX(-50%));
  }
  100% {
    @include transform(translateX(0));
  }
}

.marquee__wrapper {
  overflow-x: hidden;
  &:nth-child(even) {
    .marquee {
      @include animation-direction(reverse);
    }
  }
}

.marquee {
  @include flexbox;
  @include flex-wrap(nowrap);
  @include box-sizing(border-box);
  overflow: visible;
  &--rtl {
    @include animation(marquee-rtl, var(--marqueeSpeed), infinite, linear);
  }
  &--ltr {
    @include animation(marquee-ltr, var(--marqueeSpeed), infinite, linear);
  }
  &--btt {
    @include flex-direction(column);
    position: relative;
    .marquee__element {
      @include transform(translateY(100%));
      // &--current {
      //   @include animation(showHideMarquee, var(--marqueeVerticalSpeed), infinite, ease-in-out, 0);
      // }
      &--enter {
        @include animation(showMarquee, 0.35s, 1, ease-out, 0s, forwards);
      }
      &--stay {
        @include opacity(1);
        @include transform(translateY(0));
      }
      &--leave {
        @include animation(hideMarquee, 0.35s, 1, ease-in, 0s, forwards);
      }
      &--stopped {
        @include opacity(1);
        @include transform(translateY(0));
      }
    }
  }
  &--loading {
    * {
      @include opacity(0);
    }
  }
}

// .marquee__element {
//   .calendar__items {
//     min-width: 100vw;
//   }
// }

// Fixed message
.fixed-message {
  position: fixed;
  z-index: 10;
  background: $grey--xxd;
  width: 100%;
  top: 0;
  left: 0;
  height: var(--messageBlastHeight);
  padding: $spacing__unit*0.25*3 0 6px 0;
  @include box-sizing(content-box);
  p {
    @extend %type--s;
    text-align: center;
    padding: 0 $spacing__unit*0.25*3;
    color: $white;
    @media screen and (min-width: $s) {
      padding: 0 $spacing__unit;
    }
  }
  a {
    color: $white;
  }
}


// Cookie policy
.cookie-bar {
  position: fixed;
  background: $white;
  top: 0;
  z-index: 10;
  box-shadow: 0 12px 18px -18px rgba(0,0,0,0.15);
  .cookie-bar__wrapper {
    @include flexbox;
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items(flex-end);
    padding: $spacing__unit*0.5 $spacing__unit*0.75;
    @media screen and (min-width: $m) {
      @include flex-direction(column-reverse);
      @include align-items(end);
    }
  }
  .cookie-bar__disclaimer {
    @extend %type--xxs;
    color: $grey;
    @media screen and (min-width: $m) {
      max-width: calc(100vw - 56px);
    }
  }
  .cookie-bar__close {
    display: block;
    width: 28px;
    height: 28px;
    @include background-image('close');
    @include background-contain;
    margin: 12px 24px 8px 0;
    @media screen and (min-width: $m) {
      width: 36px;
      height: 36px;
    }
  }
  @media screen and (min-width: $m) {
    box-shadow: 0 -12px 18px -18px rgba(0,0,0,0.15);
    bottom: 0;
    top: auto;
    .cookie-bar__wrapper {
      @include flex-direction(row);
      padding: $spacing__unit*0.75 $spacing__unit;
    }
    .cookie-bar__close {
      margin-bottom: 0;
      margin: 0;
      margin-left: $spacing__unit*1.5;
    }
  }
}


// Add to Home Screen -> A2hs

.a2hs {
  @include transition(all $ease__io--sine--f);
  position: fixed;
  margin: 0 auto;
  max-width: 660px;
  // max-height: 640px;
  height: calc(100% - 120px);
  left: $spacing__unit*0.75;
  right: $spacing__unit*0.75;
  top: $spacing__unit*0.75;
  bottom: $spacing__unit*0.75;
  z-index: 103;
  background: $white;
  box-shadow: 0 0 36px -1px rgba(0,0,0,0.1);
  @media screen and (min-width: $m) {
    box-shadow: 0 0 36px -6px rgba(0,0,0,0.15);
  }
  &.a2hs--up,
  &.a2hs--down {
    height: calc(100% - 36px);
  }
  &.a2hs--chrome68 {
    height: calc(100% - 96px);
  }
  &--visible {
    @include transform(translateY(0) !important)
  }
}

.app-loading-message {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  height: 100vh;
  @include flexbox;
  @include flex-direction(column);
}

.app-loading-message__bg,
.a2hs__bg {
  width: 100%;
  padding-bottom: 0;
  @include background-image('PWA');
  @include background-cover;
  background-position: center bottom;
  max-height: calc(100vh - 340px);
  height: 100%;
}

.pa2hs__close {
  position: absolute;
  top: 0;
  right: 0;
  margin: 12px;
  width: 36px;
  height: 36px;
  @include background-image('close');
  @include background-contain;
  .material-icons {
    display: block;
  }
}

.a2hs--chrome68 {
  padding-bottom: 90px !important;
}

.app-loading-message__wrapper {
  padding: $spacing__unit*2 $spacing__unit;
  position: static;
}

.a2hs__wrapper {
  position: absolute;
  bottom: $spacing__unit*0.5;
  left: 0;
  right: 0;
  padding: $spacing__unit*0.5 $spacing__unit*0.75 $spacing__unit*0.75;
}

.app-loading-message__title,
.a2hs__title {
  @extend %type--xl;
  margin-bottom: $spacing__unit;
  display: none;
  &.a2hs__title--short {
    display: block;
    @media screen and (min-width: 400px) {
      display: none;
    }
  }
  &.a2hs__title--long {
    display: none;
    @media screen and (min-width: 400px) {
      display: block;
    }
  }
}
.app-loading-message__title {
  display: block;
}
.a2hs__instructions {
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  text-align: center;
}
.a2hs__actions {
  display: none;
}

.a2hs__icon {
  @include transform(translateY(3px));
  display: inline-block;
  margin-bottom: -2px;
  width: $type__unit*2;
  height: $type__unit*2;
  @include background-contain;
  &--ios {
    @include background-image('ios');
  }
  &--android {
    @include background-image('android');
  }
  &--firefox {
    @include background-image('firefox');
  }
}

.awwwards {
  display: none !important;
  position: fixed;
  z-index: 999;
  transform: translateY(-50%);
  top: 50%;
  right: 0;
  @include transition(all $ease__io--sine);
  &--hidden {
    right: -60px;
  }
}
