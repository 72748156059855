// Giga Design Studio - Mullet
// 03 - Components
// Components to reuse, you may need to change things sometimes

// 01 - icons

@use "sass:math";

%type--icon {
  display: block;

  &--overlay {
    color: $basic__color--trans;
    @include hover(color, $basic__color);
  }
}

%type--icon--xs {
  @extend %type--icon;
  font-size: $type__unit;
  line-height: $type__unit*1.5;
  height: $type__unit*1.5;
  width: $type__unit*1.5;
}

%type--icon--s {
  @extend %type--icon;
  font-size: $type__unit*1.25 + 1px;
  line-height: $type__unit*2;
  height: $type__unit*2;
  width: $type__unit*2;
}

%type--icon--m {
  @extend %type--icon;
  font-size: $type__unit*2;
  line-height: $type__unit*2;
  height: $type__unit*2;
  width: $type__unit*2;
}

%type--icon--l {
  @extend %type--icon;
  font-size: $type__unit*2*1.5;
  line-height: $type__unit*2*1.5;
  height: $type__unit*2*1.5;
  width: $type__unit*2*1.5;
}

// 02 - navbars and scrollspy

.nav__container {
  position: relative;
}

.nav__menu-disabled {
  pointer-events: none;
}

.nav__bar {
  position: absolute;
  z-index: 10;
  // top: 0;
  left: 0;
  width: 100%;
  @include transition(transform $ease__o--f);

  &--fixed {
    position: fixed !important;
  }

  &--sticky {
    position: fixed !important;
  }

  &--visible {
    background: $basic__color--hover;
    @include transform(translateY(0));
  }

  &--hidden {
    background: $basic__color--hover;
    @include transform(translateY(-200%));
  }
}

.nav__menu {}

.nav__item {}

.nav__link {
  &--active {}

  &--disabled {}
}

// TODO: scrollspy
.nav__anchor {}

// 03 - form elements

input,
select,
button,
textarea {
  &::placeholder {
    color: $grey;
  }

  &[type="button"],
  &[type="reset"],
  &[type="submit"],
  &[type="range"],
  &[type="radio"],
  &[type="checkbox"],
  &[type="file"],
  &[type="color"],
  &[type="image"] {
    cursor: pointer;
  }

  &[type="text"],
  &[type="number"],
  &[type="email"],
  &[type="tel"],
  &[type="password"],
  &[type="url"],
  &[type="search"],
  &[type="week"],
  &[type="month"],
  &[type="time"],
  &[type="date"],
  &[type="datetime"],
  &[type="datetime-local"] {
    &:focus {
      border-color: $primary__color;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  @include box-shadow(0 0 0 1000px $white inset);
  @include transition(background-color 9999s ease-in-out 0s);
}

button,
select {
  cursor: pointer;
}

label {
  display: block;
}

.form {

  &--group,
  &--inline {
    .form__item {
      border: 0;
    }
  }
}

.form__header {}

.form__content {}

.form__item {
  font-family: $sans;
  outline: 0;
  border-radius: 0;
  background: transparent;
  border: 0;
  padding: 0;
  @include box-sizing(border-box);

  &--border {
    border: $border__unit solid $border__color;
    background: $white;
    color: $grey--xxd;
  }

  &--borderbottom {
    background-color: transparent;
    border-color: transparent;
    border-bottom: $border__unit solid $border__color;

    &:focus {
      border-color: transparent !important;
      border-bottom-color: $primary__color !important;
    }
  }

  &--roundcorners {
    border-radius: $radius__unit;
  }
}

.form__item--xs {
  @extend .form__item;
  font-size: $type__unit;
  line-height: $type__unit*1.5;
  padding: $type__unit*0.5 $type__unit*1.25;

  &.form__item--border {
    padding: ($type__unit*0.5 - $border__unit) $type__unit*1.25;
  }

  &.form__item--rounded {
    border-radius: $type__unit*1.25;
  }
}

.form__item--s {
  @extend .form__item;
  font-size: $type__unit*1.25 + 1px;
  line-height: $type__unit*2;
  padding: $type__unit*0.5 $type__unit*1.5;

  &.form__item--border {
    padding: ($type__unit*0.5 - $border__unit) $type__unit*1.5;
  }

  &.form__item--rounded {
    border-radius: $type__unit*2;
  }
}

.form__item--m {
  @extend .form__item;
  font-size: $type__unit*1.5;
  line-height: $type__unit*2;
  padding: $type__unit $type__unit*2;

  &.form__item--border {
    padding: ($type__unit - $border__unit) $type__unit*2;
  }

  &.form__item--rounded {
    border-radius: $type__unit*2;
  }
}

.form__item--l {
  @extend .form__item;
  font-size: $type__unit*2;
  line-height: $type__unit*2*1.5;
  padding: $type__unit $type__unit*2;

  &.form__item--border {
    padding: ($type__unit - $border__unit) $type__unit*2;
  }

  &.form__item--rounded {
    border-radius: $type__unit*2*1.25;
  }
}

.form__button {
  cursor: pointer;
  text-align: center;
  @include transition(all $ease__o--xxf);
}

.form__button--primary {
  @extend .form__button;
  font-weight: $bold__unit;
  background-color: $primary__color;
  border-color: $primary__color;
  color: $primary__color--active;
  @include hover(background-color, $primary__color--hover);
  @include hover(border-color, $primary__color--hover);
}

.form__button--secondary {
  @extend .form__button;
  font-weight: $bold__unit;
  background-color: $secondary__color;
  border-color: $secondary__color;
  color: $secondary__color--active;
  @include hover(background-color, $secondary__color--hover);
  @include hover(border-color, $secondary__color--hover);
}

.form__button--basic {
  @extend .form__button;
  font-weight: $bold__unit;
  background-color: $basic__color--active;
  border-color: $basic__color--active;
  color: $basic__color--hover;
  // @include hover(background-color, $basic__color--hover);
  // @include hover(border-color, $basic__color--hover);
}

.form__button--border {
  @extend .form__button;
  font-weight: $bold__unit;
  background-color: $basic__color;
  border-color: $basic__color;
  color: $basic__color--active;
  @include hover(background-color, $basic__color--hover);
  @include hover(border-color, $basic__color--hover);
}


// 04 - collapses, dropdowns...

details>summary {
  list-style: none;
}

details>summary::marker,
details>summary::-webkit-details-marker {
  display: none;
}

details {
  &[open] {
    .rows__arrow-icon--up {
      display: block;
    }

    .rows__arrow-icon--down {
      display: none;
    }
  }

  .rows__arrow-icon--down {
    display: block;
  }

  .rows__arrow-icon--up {
    display: none;
  }

}

.collapse {

  // margin-bottom: $spacing__unit/4;
  &:last-child {
    margin-bottom: 0;
  }

  &--close {
    .collapse__wrapper {
      max-height: 0px !important;
    }
  }

  &--active {}
}

.collapse__trigger {
  cursor: pointer;
}

.collapse__arrow {
  &--open {}

  &--close {}
}

.collapse__wrapper {
  max-height: 0;
  overflow: hidden;
  @include transition(max-height $ease__io--sine);
}

.collapse__content {
  // padding: $spacing__unit/4 0 $spacing__unit/2;
}

.dropdown {
  position: relative;

  &--active {
    .dropdown__wrapper {
      display: block;
      z-index: 10;
    }
  }

  &--xs {

    .dropdown__trigger,
    .dropdown__item {
      @extend .form__item--xs;
    }
  }

  &--s {

    .dropdown__trigger,
    .dropdown__item {
      @extend .form__item--s;
    }
  }

  &--m {

    .dropdown__trigger,
    .dropdown__item {
      @extend .form__item--m;
    }
  }

  &--l {

    .dropdown__trigger,
    .dropdown__item {
      @extend .form__item--l;
    }
  }

  &--bottomaligned {
    .dropdown__wrapper {
      top: auto;
      bottom: 100%;
    }

    .dropdown__item {
      border-bottom: 0;
    }
  }

  &--topaligned {
    .dropdown__wrapper {
      bottom: auto;
      top: 100%;
    }

    .dropdown__item {
      border-top: 0;
    }
  }

  .dropdown__item {
    @extend .form__item--border;
    @extend .form__button--basic;
  }

  .dropdown__trigger {
    @extend .form__item--border;
    @extend .form__button--primary;
  }
}

.dropdown__wrapper {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  @include box-sizing(border-box);
  // width: 100%;
  // border: $border__unit solid $border__color;
}

.dropdown__link {}

// tooltip
// modal

// 05 - slider

.slider {
  &--xs {

    button[data-controls],
    i {
      @extend %type--icon--xs;
    }
  }

  &--s {

    button[data-controls],
    i {
      @extend %type--icon--s;
    }
  }

  &--m {

    button[data-controls],
    i {
      @extend %type--icon--m;
    }
  }

  &--l {

    button[data-controls],
    i {
      @extend %type--icon--l;
    }
  }

  &--progress {
    .tns-nav {
      button {
        &.tns-nav-active {
          background-color: transparent;
          @include linear-gradient(90deg, $primary__color 0%, $basic__color--trans 0%);
        }
      }
    }
  }
}

.tns-outer {
  position: relative;

  button {
    @extend .form__item;

    i {
      @extend %type--icon;
      @extend %type--icon--overlay;
    }
  }
}

.tns-nav,
button[data-controls],
button[data-action="stop"],
button[data-action="start"] {
  position: absolute;
  z-index: 1;
  overflow: hidden;
}

button[data-controls] {
  @include flexbox;
  @include align-items(center);
  @include hover(color, $black, i);
  top: 0;
  bottom: 0;
  width: 50%;
  font-size: 0;
  color: transparent;

  &>div {
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    @include transition(transform $ease__io--sine);
    will-change: transform;
    width: $spacing__unit*2;
    height: $spacing__unit*2;
    line-height: $spacing__unit*2;
    background: $white;
    box-shadow: 0 0 18px -3px rgba(0, 0, 0, 0.15);
    text-align: center;

    // margin: $spacing__unit;
    // border-radius: 100%;
    i {
      color: $black;
      width: $spacing__unit;
      height: $spacing__unit;
    }
  }

  &[data-controls="prev"] {
    @include hover(transform, translateX($spacing__unit*0.5), "& > div");
    @include justify-content(flex-start);
    left: 0;
  }

  &[data-controls="next"] {
    @include hover(transform, translateX(-$spacing__unit*0.5), "& > div");
    @include justify-content(flex-end);
    right: 0;
  }
}

.tns-nav {
  top: 0;
  left: 0;
  right: 0;
  padding: $type__unit;
  @include flexbox;
  @include justify-content(space-between);

  button {
    margin: 0 math.div($type__unit, 6);
    height: math.div($type__unit, 6);
    width: 100%;
    background-color: $basic__color--trans;
    @include hover(background-color, $basic__color);

    &.tns-nav-active {
      background-color: $primary__color;
    }
  }
}

button[data-action="stop"],
button[data-action="start"] {
  display: none;
}

//

.overlay {
  position: relative;

  &--fullheight {
    .overlay__background {
      height: 100vh;
    }
  }

  &--squared {
    .overlay__background {
      padding-bottom: 100%;
    }
  }

  &--rect {
    .overlay__background {
      padding-bottom: 50%;
    }
  }

  &--centertext {
    .overlay__foreground {
      @include justify-content(center);
    }
  }

  &--roundcorners {

    .overlay__background,
    .overlay__foreground {
      border-radius: $radius__unit*4;
    }
  }

  &--centered {
    &--dark {
      .overlay__foreground {
        color: $grey--xxl;
        background-color: $black--trans;
      }
    }

    &--bright {
      .overlay__foreground {
        color: $grey--xxd;
        background-color: $white--trans;
      }
    }
  }

  &--bottomaligned {
    .overlay__foreground {
      @include align-items(flex-end);
    }

    &--dark {
      .overlay__foreground {
        color: $grey--xxl;
        @include linear-gradient(0deg, $black--trans--d, transparent);
      }
    }

    &--bright {
      .overlay__foreground {
        color: $grey--xxd;
        @include linear-gradient(0deg, $white--trans--d, transparent);
      }
    }
  }

  &--topaligned {
    .overlay__foreground {
      @include align-items(flex-start);
    }

    &--dark {
      .overlay__foreground {
        color: $grey--xxl;
        @include linear-gradient(180deg, $black--trans--d, transparent);
      }
    }

    &--bright {
      .overlay__foreground {
        color: $grey--xxd;
        @include linear-gradient(180deg, $white--trans--d, transparent);
      }
    }
  }
}

.overlay__background {
  width: 100%;
  height: 100%;
  @include background-cover;
}

.overlay__foreground {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: $type__unit*2;
  @include box-sizing(border-box);
  @include flexbox;
  @include align-items(center);
}

.calendar__elements,
.sharer__elements {
  @include box-sizing(border-box);
  @include transition(opacity $ease__io--sine--f);
  @include transform(scale(0));
  @include transform-origin(100% 0%);
  opacity: 0;
  // display: none;
  position: absolute;
  top: -10px;
  right: -10px;
  width: $spacing__unit*7;
  background-color: white;
  padding: $type__unit*0.75 $type__unit*3 $type__unit*0.75 $type__unit*1.5;
  box-shadow: 0 0 18px -1px rgba(0, 0, 0, 0.1);

  @media screen and (min-width: $m) {
    box-shadow: 0 0 18px -6px rgba(0, 0, 0, 0.15);
  }

  .icon-outlook {
    display: none;
  }

  a,
  span {
    display: block;
    color: $grey;
    @include transition(all $ease__io--sine);
    @include hover(color, $black);
  }

  &--open {
    @include transform(scale(1));
    opacity: 1;
    z-index: 2;
  }
}

.sharer__elements {
  right: 44px;
}

.sharer__element {
  padding-bottom: 12px;

  &:last-child {
    padding-bottom: 0;
  }

  @media screen and (min-width: $m) {
    padding-bottom: 0;
    padding-left: 18px;
  }
}