// Giga Design Studio - Mullet
// 04 - Theme
// Basic theme setup

html {
  font-size: 10px;
  &.html--scroll-smooth {
    scroll-behavior: smooth;
    .page-overlay {
      scroll-behavior: smooth;
    }
  }
  &.html--no-scroll {
    // Block HTML scrolling
    // height: 100%;
    width: 100%;
    overflow: hidden;
    body {
      overscroll-behavior-y: contain;
      width: 100%;
    }
  }
  &.overflowscrolling {
    &.html--no-scroll {
      position: fixed;
    }
  }
}

%type--xxs {
  font-size: $type__unit*1.25;
  line-height: $type__unit*2;
}

%type--xs {
  font-size: $type__unit*1.25;
  line-height: $type__unit*2;
  @media screen and (min-width: $s) {
    font-size: $type__unit*1.5;
    line-height: $type__unit*2.5;
  }
}

%type--s {
  font-size: $type__unit*1.5;
  line-height: $type__unit*2;
  font-family: $ui;
  letter-spacing: 0;
  // font-weight: normal;
  @media screen and (min-width: $m) {
    font-size: $type__unit*1.25;
  }
  @media screen and (min-width: $l) {
    font-size: $type__unit*1.5;
  }
}

%type {
  font-size: $type__unit*1.5;
  line-height: $type__unit*2.5;
  font-family: $ui;
}

%type--m {
  font-size: 21px;
  line-height: 30px;
  font-family: $ui;
  letter-spacing: 0;
  @media screen and (min-width: $xl) {
    font-size: 24px;
  }
}

%type--l {
  font-family: $sans;
  font-weight: $bold__unit;
  font-size: $type__unit*2;
  line-height: $type__unit*3;
  letter-spacing: -0.5px;
  @media screen and (min-width: $xs) {
    font-size: $type__unit*3;
    line-height: $type__unit*3.5;
    letter-spacing: -1px;
  }
}

%type--xl {
  font-family: $sans;
  letter-spacing: -1px;
  font-weight: $bold__unit;
  font-size: $type__unit*2.5;
  line-height: $type__unit*3;
  @media screen and (min-width: $xs) {
    font-size: $type__unit*3;
    line-height: $type__unit*3.5;
  }
}

%type--xxl {
  font-family: $sans;
  font-weight: normal;
  font-size: $type__unit*3;
  line-height: 1;
  letter-spacing: 0;
  letter-spacing: -1.75px;
  span {
    display: block;
    @include transform(translateX(-2.75px));
  }
  @media screen and (min-width: $xxs) {
    font-size: $type__unit*4;
    letter-spacing: -2px;
    span {
      @include transform(translateX(-3px));
    }
  }
  @media screen and (min-width: $xs) {
    font-size: $type__unit*5;
    letter-spacing: -2.25px;
    span {
      @include transform(translateX(-3.66px));
    }
  }
  @media screen and (min-width: $l) {
    font-size: $type__unit*6;
    letter-spacing: -2.5px;
    span {
      @include transform(translateX(-4.5px));
    }
  }
  @media screen and (min-width: $xl) {
    font-size: $type__unit*7;
    letter-spacing: -2.75px;
    span {
      @include transform(translateX(-5.33px));
    }
  }
  @media screen and (min-width: $xxl) {
    font-size: $type__unit*8;
    letter-spacing: -3px;
    span {
      @include transform(translateX(-6px));
    }
  }
}

%type--xxxl {
  font-family: $sans;
  letter-spacing: -2px;
  font-weight: $bold__unit;
  font-size: $type__unit*4;
  line-height: 1;
  span {
    display: block;
    @include transform(translateX(-3px));
  }
  @media screen and (min-width: $xs) {
    font-size: $type__unit*7;
    line-height: $type__unit*6;
    letter-spacing: -3px;
    span {
      @include transform(translateX(-6px));
    }
  }
  @media screen and (min-width: $xl) {
    font-size: $type__unit*13;
    line-height: $type__unit*11;
    letter-spacing: -4px;
    span {
      @include transform(translateX(-9px));
    }
  }
}

%grid-gap {
  grid-gap: $spacing__unit;
  @media screen and (min-width: $xxl) {
    grid-gap: $spacing__unit*2;
  }
}

%grid2 {
  @include grid;
  @include grid-template-columns(1fr 1fr);
  @extend %grid-gap;
}

%grid3 {
  @include grid;
  @include grid-template-columns(1fr 1fr 1fr);
  @extend %grid-gap;
}

%grid4 {
  @include grid;
  @include grid-template-columns(1fr 1fr 1fr 1fr);
  @extend %grid-gap;
}

%grid6 {
  @include grid;
  @include grid-template-columns(1fr 1fr 1fr 1fr 1fr 1fr);
  @extend %grid-gap;
}

%grid12 {
  @include grid;
  @include grid-template-columns(1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr);
  @extend %grid-gap;
}

%section-wrapper {
  padding: 0 $spacing__unit*1;
  max-width: 1680px;
  margin: 0 auto;
  @media screen and (min-width: $xs) {
    padding: 0 $spacing__unit*2;
  }
  @media screen and (min-width: $xxl) {
    padding: 0 $spacing__unit*3;
  }
}

%section-spacing {
  margin-top: $spacing__unit*2;
  @media screen and (min-width: $xs) {
    margin-top: $spacing__unit*3;
  }
  @media screen and (min-width: $s) {
    margin-top: $spacing__unit*4;
  }
}

%section-spacing--negative {
  margin-top: -$spacing__unit*0.5;
  @media screen and (min-width: $xs) {
    margin-top: -$spacing__unit*0.75;
  }
  @media screen and (min-width: $m) {
    margin-top: -$spacing__unit*1;
  }
  @media screen and (min-width: $xl) {
    margin-top: -$spacing__unit*2;
  }
}

.link__button {
  @extend %type;
  @include flexbox;
  @include hover(transform, translate(3px, -3px), i);
  // @include hover(transform, scale(1), "i::before");
  @include hover(color, $black);
  @include hover(transform, translateX($spacing__unit*0.5), span);
  font-weight: $bold__unit;
  i {
    @include background-cover;
    @include background-image('link');
    @include transition(all $ease__io--sine);
    @include transition-delay(0.1s);
    @include transform(translate(-3px, -3px));
    text-align: center;
    line-height: 30px;
    height: 36px;
    width: 36px;
    margin-right: 6px;
    position: relative;
    // @media screen and (min-width: $xxl) {
    //   line-height: 36px;
    //   width: 36px;
    //   height: 36px;
    // }
    // &::before {
    //   @include transition(all $ease__io--sine);
    //   @include transform(scaleX(0));
    //   @include transform-origin(0 0);
    //   content: '';
    //   z-index: -1;
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   bottom: 0;
    //   right: 0;
    //   background-color: $grey--xxl;
    // }
  }
  span {
    @include transition(all $ease__io--sine);
    @include transition-delay(0.1s);
  }
  &--big {
    @extend %type--xl;
    @include hover(transform, translate($spacing__unit*0.125, -3px), i);
    i {
      line-height: $type__unit*3.5;
      height: $type__unit*4;
      width: $type__unit*6;
      @include transform(translate(-3px, -3px));
    }
  }
}

.slider__arrow--left {
  @include background-cover;
  @include background-image('arrow_left');
}

.slider__arrow--right {
  @include background-cover;
  @include background-image('arrow_right');
}

@keyframes scroll {
  0% {
    margin-top: 100vh;
  }
  100% {
    margin-top: calc(100vh - 324px);
  }
};

@keyframes pulse {
  0% {
    @include transform(scale(1));
    background-color: $grey--d;
  }
  50% {
    @include transform(scale(3));
    background-color: $grey--d--trans;
  }
  100% {
    @include transform(scale(1));
    background-color: $grey--d;
  }
};

@keyframes steps {
  0% {
    opacity: 1;
    // visibility: visible;
  }
  50% {
    opacity: 0;
    // visibility: hidden;
  }
  100% {
    opacity: 1;
    // visibility: visible;
  }
};

@keyframes breath {
  0% {
    @include transform(translateX(0));
  }
  50% {
    @include transform(translateX($spacing__unit*0.125));
  }
  100% {
    @include transform(translateX(0));
  }
};


@keyframes showDown {
  0% {
    @include transform(translateY(-200%));
    opacity: 0;
  }
  100% {
    @include transform(translateY(0%));
    opacity: 1;
  }
};

@keyframes showUp {
  0% {
    @include transform(translateY(200%));
    opacity: 0;
  }
  100% {
    @include transform(translateY(0%));
    opacity: 1;
  }
};

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
};

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
};

body {
  @extend %type;
  background: $basic__color;
  color: $basic__color--active;
  overflow-x: hidden;
}

* {
  @include box-sizing(border-box);
  @include selection {
    background-color: $selection__color--bg;
    color: $selection__color--txt;
  }
}

a {
  color: $black;
  cursor: pointer;
  @include transition(all $ease__io--sine);
  @include hover(color, $basic__color--hover);
}

figure {
  img {
    display: block;
  }
  figcaption {
    @extend %type--xxs;
    color: $grey;
    text-align: left;
    margin-top: $spacing__unit*0.25;
  }
}
