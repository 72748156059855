// Giga Design Studio - Mullet
// 05 - Templates
// This one for templating


// Main
.TEST2 {
  display: none;
}



.skip-to-main {
  position: fixed;
  z-index: 10000;
  top: $spacing__unit*0.5;
  left: $spacing__unit*0.5;
  padding: $spacing__unit*0.5 $spacing__unit;
  transform: translateX(-150%);
  @include transition(all $ease__io--sine);
  @include transition-delay(0.2s);
  margin: 0 auto;
  background: $grey--xl;
  text-transform: uppercase;
  border-radius: 0;
  box-shadow: 0 0 18px -1px rgba(0, 0, 0, 0.1);
  @extend %type--xxs;
  @include appeareance(none);

  @media screen and (min-width: $m) {
    box-shadow: 0 0 18px -6px rgba(0, 0, 0, 0.15);
  }

}

.skip-to-main:focus {
  transform: translateY(0);
}

.app--fixed-message {
  margin-top: var(--messageBlastHeight);
}

.app--loading {
  &>*:not(.app-loading-message) {
    @include opacity(0);
  }
}

main {
  overflow: hidden;
  background: $white;
  padding-bottom: $spacing__unit*2;
  min-height: 100vh;
  box-shadow: 0 -12px 18px -18px rgba(0, 0, 0, 0.15);

  &.main--anchors {
    .section {
      @media screen and (min-width: $m) {
        padding-top: $spacing__unit*6.5;

        &--first {
          padding-top: $spacing__unit*7;
        }
      }
    }
  }

  >* {
    @include transition(all $ease__io--sine);
  }
}

:root {
  --homeMarginTopIntro: calc(100vh - 200px);
}

// Images
.index,
.program,
.program-slug,
.speakers,
.about {

  .cover__image,
  img {
    mix-blend-mode: multiply;
    @include filter(grayscale, 1);
  }

  .page-overlay--awards {

    .cover__image,
    img {
      mix-blend-mode: normal;
    }
  }
}


// General
.section {
  padding-top: $spacing__unit*2;
  padding-bottom: $spacing__unit*1;

  @media screen and (min-width: $xs) {
    padding-top: $spacing__unit*4;
    padding-bottom: $spacing__unit*0;
  }

  &--desktop-only {
    display: none;

    @media screen and (min-width: $m) {
      display: block;
    }
  }

  &--eventbrite {
    @media screen and (max-width: $m) {
      padding-top: 0px;
    }
  }

  &--first {
    @media screen and (min-width: $m) {
      padding-top: $spacing__unit*7.5;
    }
  }

  &--event {
    padding-bottom: $spacing__unit*6.25;

    @media screen and (min-width: $m) {
      padding-bottom: $spacing__unit*2;
    }
  }

  &--cover {
    padding-top: $spacing__unit*3;

    @media screen and (min-width: $s) {
      padding-top: $spacing__unit*4;
    }

    @media screen and (min-width: $m) {
      padding-top: $spacing__unit*7.5;
    }
  }

  &--archive {
    padding-top: 0;
  }

  &--normal {
    &+.section--poster {
      padding-top: $spacing__unit*2;

      @media screen and (min-width: $xs) {
        padding-top: $spacing__unit*4;
      }
    }
  }

  &--poster {
    padding-top: 0;
    padding-bottom: 0;

    @media screen and (min-width: $xs) {
      padding-top: $spacing__unit*4;
      padding-bottom: $spacing__unit*0;
    }

    &+.section--normal {
      padding-top: $spacing__unit*2;

      @media screen and (min-width: $xs) {
        padding-top: $spacing__unit*4;
      }
    }
  }

  &--intro {
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 0;
    z-index: -1;

    &+main {
      @include transition(margin $ease__io--sine--s);
      margin-top: var(--homeMarginTopIntro);
    }
  }

  &--calendar-program-wrapper {
    padding: 0 !important;
    min-height: 100vh;
  }

  // &--calendar {
  //
  // }
}

.iphone {
  .page-overlay {
    .section--event {
      padding-bottom: $spacing__unit*8.5;
    }
  }
}

.section__information-line {
  a {
    text-decoration: underline;
  }

  +.section__information-line {
    padding-top: $spacing__unit*0.5;
  }
}

.section__information-label {
  text-transform: uppercase;
}

// Cover
.section__cover {
  @extend %grid12;
  @extend %section-wrapper;

  .cover__image {
    height: 100vw;
    @include grid-child(1, 13, auto, auto);

    @media screen and (min-width: $s) {
      height: 50vh;
      @include grid-child(2, 12, auto, auto);
    }

    @media screen and (min-width: $m) {
      @include grid-child(3, 11, auto, auto);
    }

    @media screen and (min-width: $l) {
      @include grid-child(5, 9, auto, auto);
    }
  }

  &+.section__header {
    @extend %section-spacing;
  }
}

.cover__image {
  @include background-contain;
  width: 100%;
}

.section__video {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;

  video {
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    display: block;
    object-fit: cover;
  }
}


// Header
.section__header {
  @extend %section-wrapper;

  &--grey {
    .section__title {
      color: $grey--xxl;
      position: relative;
      z-index: 0;
      width: 200%;
    }

    .section__subtitle,
    &+* {
      @extend %section-spacing--negative;
    }
  }

  &--small {
    .section__title {
      @extend %type--xxl;
      font-weight: $bold__unit;
    }
  }

  &+.section__content {
    @extend %section-spacing;
  }

  &+.section__embedded-video {
    @extend %section-spacing;
  }
}

.section__titling {
  @extend %grid12;
  grid-row-gap: $spacing__unit*0.25;
}

.section__title,
.section__label {
  @include grid-child(1, 13, auto, auto);

  @media screen and (min-width: $s) {
    @include grid-child(2, 13, auto, auto);
  }

  @media screen and (min-width: $l) {
    @include grid-child(3, 13, auto, auto);
  }
}

.section__title {
  @extend %type--xxxl;

  &+.section__subtitle {
    margin-top: $spacing__unit*0.5;
  }
}

.section__subtitle {
  @extend %type--xl;
  @include grid-child(1, 13, auto, auto);

  &--light {
    font-weight: $light__unit;
  }

  @media screen and (min-width: $xs) {
    @include grid-child(1, 11, auto, auto);
  }

  @media screen and (min-width: $s) {
    @include grid-child(2, 12, auto, auto);
  }

  @media screen and (min-width: $m) {
    @include grid-child(2, 9, auto, auto);
  }

  @media screen and (min-width: $l) {
    @include grid-child(3, 9, auto, auto);
  }

  @media screen and (min-width: $xxl) {
    @include grid-child(3, 8, auto, auto);
  }

  &+.section__subtitle {
    margin-top: $spacing__unit*0.5;
  }
}

.section__label {
  @extend %type--xs;
  color: $grey;
  text-transform: uppercase;

  &+.section__title {
    margin-top: $spacing__unit*0.5;
  }
}

.section__embedded-video {
  .video-embedded {
    @include grid-child(1, 13, auto, auto);

    @media screen and (min-width: $s) {
      @include grid-child(2, 12, auto, auto);
    }

    @media screen and (min-width: $l) {
      @include grid-child(3, 11, auto, auto);
    }
  }

  &+.section__content {
    @extend %section-spacing;
  }
}


// Sections
.section__cards,
.section__grid,
.section__rows,
.section__gallery,
.section__timeline,
.section__thumbnails,
.section__newsletter,
.section__logos,
.section__speakers,
.section__related,
.archive__wrapper {
  @extend %section-spacing;
}

.section__content,
.section__grid,
.section__rows,
.section__timeline,
.section__thumbnails,
.section__newsletter,
.section__logos,
.section__poster,
.section__speakers,
.section__related,
.section__calendar,
.section__embedded-video {
  @extend %section-wrapper;
}

// Sections background
.content__background,
.card__background,
.grid__background,
.rows__background,
.gallery__background,
.timeline__background,
.newsletter__background,
.poster__background,
.speakers__background,
.related__background,
.section__embedded-video {
  @extend %grid12;

  .section__title {
    @include grid-child(1, 13, auto, auto);

    @media screen and (min-width: $s) {
      @include grid-child(2, 13, auto, auto);
    }

    @media screen and (min-width: $l) {
      @include grid-child(3, 13, auto, auto);
    }
  }

  &--grey {
    &+div {
      @extend %section-spacing--negative;
      position: relative;
      z-index: 1;
    }

    .section__title {
      color: $grey--xxl;
      position: relative;
      z-index: 0;
      width: 200%;
    }
  }

  &--normal,
  &--small {
    &+div {
      @extend %section-spacing;
    }
  }

  &--small {
    .section__title {
      @extend %type--xxl;
    }
  }
}

.card__background .section__title {
  color: $grey--l;
  @include opacity(0.4);
  @include grid-child(1, 13, auto, auto);

  @media screen and (min-width: $m) {
    margin-left: -8.3%;
  }
}

// Sections wrapper
.content__wrapper,
.grid__wrapper,
.rows__wrapper,
.thumbnails__wrapper,
.newsletter__wrapper,
.logos__wrapper,
.poster__wrapper,
.related__wrapper,
.speakers__item,
.calendar__item,
.breakout__item,
.breakout__wrapper,
.calendar__overview {
  @extend %grid12;
}


// Posters
.section__poster {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: $spacing__unit*0;
    right: $spacing__unit*0;

    @media screen and (min-width: $xs) {
      left: $spacing__unit*1;
      right: $spacing__unit*1;
    }

    @media screen and (min-width: $l) {
      left: $spacing__unit*2;
      right: $spacing__unit*2;
    }

    @media screen and (min-width: $xxl) {
      left: $spacing__unit*3;
      right: $spacing__unit*3;
    }
  }

  &--yellow {
    &::before {
      background: $yellow;
    }

    figure {
      background: $yellow;
    }
  }

  &--violet {
    &::before {
      background: $violet;
    }

    figure {
      background: $violet;
    }
  }

  &--light-grey {
    &::before {
      background: $grey--xl;
    }

    figure {
      background: $grey--xl;
    }
  }

  &--dark-grey {
    * {
      color: $white;
    }

    &::before {
      background: $grey--xxd;
    }

    .link__button {
      i {
        @include background-image('link--white');
      }
    }

    figure {
      background: $white;
    }
  }

  .section__label {
    color: $basic__color--active;
  }

  .section__link {
    margin-top: $spacing__unit;

    @media screen and (min-width: $xxl) {
      margin-top: $spacing__unit*2;
    }
  }

  .poster__gallery {
    position: relative;
    height: 50vw;
    @include grid-child(1, 13, auto, auto);

    @media screen and (min-width: $xs) {
      height: 360px;
    }

    @media screen and (min-width: $m) {
      @include grid-child(7, 13, auto, auto);
      height: 360px;
    }

    @media screen and (min-width: $xxl) {
      @include grid-child(8, 13, auto, auto);
      height: 480px;
    }
  }

  .gallery__wrapper .gallery__slider.tns-slider {
    display: flex;
  }

  .section__gallery {
    overflow-x: scroll;
  }

  .tns-controls,
  .tns-liveregion {
    display: none;
  }

  .gallery__slider {

    img {
      height: 50vw;
      max-height: 360px;

      // mix-blend-mode: multiply;
      // @include filter(grayscale, 1);
      @media screen and (min-width: $m) {
        height: 45vw;
      }

      @media screen and (min-width: $xxl) {
        max-height: 480px;
      }
    }
  }

  .gallery__wrapper {
    position: absolute;
    overflow-y: hidden;
    overflow-x: scroll;
    left: -$spacing__unit;
    right: -$spacing__unit;

    @media screen and (min-width: $xs) {
      left: 0;
      right: -$spacing__unit*2;
    }

    @media screen and (min-width: $xxl) {
      right: 0;
    }
  }
}

.poster__container {
  position: relative;
  z-index: 1;
  padding: $spacing__unit*2 0;

  // @media screen and (min-width: $xs) {
  //   padding: $spacing__unit*2 0;
  // }
  @media screen and (min-width: $xxl) {
    padding: $spacing__unit*3 0;
  }
}

.poster__wrapper {
  grid-gap: $spacing__unit;
  margin-top: $spacing__unit;

  @media screen and (min-width: $xs) {
    margin-top: $spacing__unit*2;
    grid-row-gap: $spacing__unit*2;
  }

  @media screen and (min-width: $xxl) {
    margin-top: $spacing__unit*3;
  }
}

.poster__content {
  @include grid-child(1, 13, auto, auto);

  @media screen and (min-width: $xs) {
    @include grid-child(3, 9, auto, auto);
  }

  @media screen and (min-width: $m) {
    @include grid-child(3, 7, auto, auto);
  }

  @media screen and (min-width: $l) {
    @include grid-child(4, 7, auto, auto);
  }
}


// Content
.section__content,
.newsletter__content {
  .content__wrapper {
    &>div {
      &:nth-child(odd) {
        @include grid-child(1, 13, auto, auto);

        @media screen and (min-width: $xs) {
          @include grid-child(1, 7, auto, auto);
        }

        @media screen and (min-width: $m) {
          @include grid-child(3, 7, auto, auto);
        }

        @media screen and (min-width: $l) {
          @include grid-child(4, 7, auto, auto);
        }

        &.section__side {
          @media screen and (min-width: $m) {
            @include grid-child(2, 6, auto, auto);
          }

          @media screen and (min-width: $l) {
            @include grid-child(3, 6, auto, auto);
          }
        }

        &.section__map {
          @include grid-child(1, 13, auto, auto);

          @media screen and (min-width: $m) {
            @include grid-child(1, 7, 2, 3);
          }
        }
      }

      &:nth-child(even) {
        @include grid-child(1, 13, auto, auto);

        @media screen and (min-width: $xs) {
          @include grid-child(7, 13, auto, auto);
        }

        @media screen and (min-width: $m) {
          @include grid-child(7, 11, auto, auto);
        }

        @media screen and (min-width: $l) {
          @include grid-child(7, 10, auto, auto);
        }

        &.section__side {
          &--big {
            @media screen and (min-width: $m) {
              @include grid-child(6, 12, auto, auto);
            }

            @media screen and (min-width: $xxl) {
              @include grid-child(6, 9, auto, auto);
            }
          }

          &--small {
            @include flexbox;
            @include align-items(flex-end);
          }
        }
      }

      &.content__grid {
        @extend %grid-gap;

        @media screen and (min-width: $xs) {
          @include grid;
          @include grid-template-columns(repeat(2, 1fr));
          @include grid-child(1, 13, auto, auto);
        }

        @media screen and (min-width: $m) {
          @include grid-child(3, 11, auto, auto);
        }

        @media screen and (min-width: $l) {
          @include grid-child(4, 10, auto, auto);
        }
      }
    }
  }

  &--3Columns {
    .content__wrapper {
      &>div {

        &:nth-child(even),
        &:nth-child(odd) {
          @include grid-child(1, 13, auto, auto);

          @media screen and (min-width: $s) {
            @include grid-child(3, 13, auto, auto);
          }

          @media screen and (min-width: $m) {
            @include grid-child(3, 11, auto, auto);
          }

          @media screen and (min-width: $l) {
            @include grid-child(4, 10, auto, auto);
          }
        }
      }
    }
  }

  &--fullWidth {
    .content__wrapper {
      &>div {

        &:nth-child(even),
        &:nth-child(odd) {
          @include grid-child(1, 13, auto, auto);

          @media screen and (min-width: $s) {
            @include grid-child(3, 13, auto, auto);
          }

          @media screen and (min-width: $m) {
            @include grid-child(3, 11, auto, auto);
          }

          @media screen and (min-width: $l) {
            @include grid-child(4, 10, auto, auto);
          }
        }
      }
    }
  }

  &--nested {
    .content__wrapper {
      &>div {
        &:nth-child(2) {
          &.section__paragraph {
            @media screen and (min-width: $xs) {
              @include grid-child(7, 13, 1, 3);
            }

            @media screen and (min-width: $m) {
              @include grid-child(7, 11, 1, 3);
            }

            @media screen and (min-width: $l) {
              @include grid-child(7, 10, 1, 3);
            }
          }
        }

        &:nth-child(3) {
          &.section__map {
            @media screen and (min-width: $m) {
              @include grid-child(1, 7, 2, 3);
            }
          }

          &.section__side--small {
            @media screen and (min-width: $m) {
              @include grid-child(2, 6, 2, 3);
            }

            @media screen and (min-width: $l) {
              @include grid-child(3, 6, 2, 3);
            }
          }
        }
      }
    }
  }
}

.section__paragraph--columns {
  @media screen and (min-width: $m) {
    column-count: 3;
  }
}

.section__content {
  @media screen and (min-width: $l) {
    margin-top: $spacing__unit*4;
  }
}


// Paragraphs
.section__paragraph,
.grid__content,
.rows__content {

  h2,
  h3,
  h4,
  h5 {
    @extend %type--m;
  }

  *+figure {
    margin-top: $spacing__unit;

    @media screen and (min-width: $l) {
      margin-top: $spacing__unit*2;
    }
  }

  figure {
    margin-bottom: $spacing__unit;

    @media screen and (min-width: $l) {
      margin-bottom: $spacing__unit*2;
    }
  }

  table {
    @extend %type--xxs;
    width: 100%;
    margin-top: $spacing__unit*1.5;
    color: $grey;

    tr {
      &:first-child {
        color: $black;

        td {
          padding-bottom: $spacing__unit*0.5;
        }
      }
    }

    td {
      width: 33.333%;
    }
  }

  h6 {
    @extend %type--xxs;
    color: $grey;
  }

  a {
    text-decoration: underline;
  }

  ul,
  ol {
    margin-bottom: $spacing__unit*0.5;

    li {
      list-style: disc;
      // margin-top: $spacing__unit*0.5;
      margin-top: 0;
      margin-left: $spacing__unit;
    }
  }
}

// <p> space
.speakers__biography,
.section__paragraph,
.grid__content,
.rows__content,
.content__grid {
  p {
    padding-bottom: $spacing__unit*0.5;

    &:last-of-type {
      padding-bottom: 0;
    }
  }

  // ul {
  //
  // }
}


// Side
.section__side {
  &--big {
    margin-top: $spacing__unit*0.5;

    @media screen and (min-width: $xs) {
      margin-top: $spacing__unit*3;
    }

    @media screen and (min-width: $xl) {
      margin-top: $spacing__unit*2;
    }

    .section__link {
      .link__button {
        @extend %type--xl;
        @include hover(transform, translate($spacing__unit*0.125, -3px), i);

        i {
          line-height: $type__unit*3.5;
          height: $type__unit*4;
          width: $type__unit*4;
          @include transform(translate(-3px, -3px));
        }
      }
    }
  }
}


// Map
.section__map {
  @include grid;
  @include grid-template-columns(1fr 1fr 1fr 1fr 1fr 1fr);
  @extend %grid-gap;
  margin-top: $spacing__unit;

  @media screen and (min-width: $xs) {
    margin-top: $spacing__unit*3;
  }

  @media screen and (min-width: $m) {
    margin-top: 0;
  }

  @media screen and (min-width: $l) {
    margin-top: $spacing__unit*2.5;
  }

  @media screen and (min-width: $xl) {
    margin-top: $spacing__unit*2;
  }

  .map__info {
    @include grid-child(1, 5, 1, 1);
    @extend %type--xxs;
    color: $grey;

    @media screen and (min-width: $xxs) {
      @include grid-child(1, 3, 1, 1);
    }
  }

  .map__embed {
    @include grid-child(1, 7, 2, 2);
    overflow: hidden;

    @media screen and (min-width: $xxs) {
      @include grid-child(3, 7, 1, 2);
    }

    @media screen and (min-width: $s) {
      @include grid-child(3, 6, 1, 2);
    }

    @media screen and (min-width: $m) {
      @include grid-child(3, 7, 1, 2);
    }

    @media screen and (min-width: $l) {
      @include grid-child(3, 6, 1, 2);
    }

    .map__wrapper {
      position: relative;
      width: 100%;
      padding-bottom: 100%;
      border-radius: $radius__unit*0;

      .map__element {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;

        .vue-google-map {
          height: 100%;

          .map-view {
            border-radius: $radius__unit*0;
            box-shadow: 0 0 24px -8px rgba(0, 0, 0, 0.15);

            .gm-style {
              iframe {
                +div {
                  display: none;
                }
              }

              .gmnoprint {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  .map__link {
    padding-top: $spacing__unit*0.5;
  }
}


// Logos
.logos__wrapper {
  border-top: 1px solid $grey--l;
  padding: $spacing__unit 0;

  &:last-child {
    border-bottom: 1px solid $grey--l;
  }
}

.logos__position {
  @include grid-child(1, 13, 1, 1);
  @include grid;
  @extend %grid-gap;
  grid-row-gap: 0;

  @media screen and (min-width: $xxs) {
    @include grid-template-columns(1fr 1fr);
  }

  @media screen and (min-width: $m) {
    @include grid-template-columns(1fr 1fr 1fr 1fr);
  }
}

.logos__element {
  img {
    margin: 0;
  }

  span {
    font-size: 0;
  }

  a,
  img {
    display: block;
  }
}


// Rows
.section__rows {
  .rows__wrapper {
    grid-row-gap: 0;
  }

  .rows__element {
    box-shadow: 0 15px 18px -18px rgba(0, 0, 0, 0.1);
    padding: $spacing__unit 0;
    @include grid-child(1, 13, auto, auto);

    @media screen and (min-width: $m) {
      @include grid-child(3, 11, auto, auto);
    }

    @media screen and (min-width: $l) {
      @include grid-child(4, 13, auto, auto);
    }
  }

  .rows__header {
    @include transition(all $ease__io--sine);
    @include hover(transform, translateY(3px), '.rows__arrow');
    @include flexbox;
    @include justify-content(space-between);

    @media screen and (min-width: $m) {
      overflow: hidden;
    }
  }

  .rows__arrow {
    @include transition(transform $ease__io--sine--f);
    will-change: transform;

    div {
      @include background-image('up');
      width: $spacing__unit;
      height: $spacing__unit;
    }
  }

  .rows__arrow-icon--up {
    @include background-image('up');
  }

  .rows__arrow-icon--down {
    @include background-image('up');
    @include transform(rotate(180deg));
  }

  .rows__title {
    font-weight: $bold__unit;
    max-width: $spacing__unit*33;
  }

  .rows__content {
    @include grid;
    @include grid-template-columns(1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr);
    @extend %grid-gap;

    @media screen and (min-width: $m) {
      @include grid-template-columns(1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr);
    }

    @media screen and (min-width: $l) {
      @include grid-template-columns(1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr);
    }
  }

  .collapse__content {
    padding: $spacing__unit 0 0;
    @include grid-child(1, 13, auto, auto);

    @media screen and (min-width: $s) {
      padding: $spacing__unit*2 0 $spacing__unit;
      @include grid-child(2, 12, auto, auto);
      font-size: 21px;
      line-height: 30px;
    }

    @media screen and (min-width: $m) {
      @include grid-child(1, 9, auto, auto);
    }

    @media screen and (min-width: $l) {
      @include grid-child(1, 7, auto, auto);
    }

    @media screen and (min-width: $xl) {
      font-size: 24px;
      line-height: 36px;
    }
  }

  &--big {
    .collapse__content {
      @media screen and (min-width: $m) {
        padding: $spacing__unit 0 $spacing__unit*2;
      }
    }

    .rows__element {
      padding: $spacing__unit 0;

      @media screen and (min-width: $m) {
        padding: $spacing__unit 0 0;
      }
    }

    .rows__header {
      @media screen and (min-width: $m) {
        height: $type__unit*4;
      }

      @media screen and (min-width: $l) {
        height: $type__unit*5;
      }

      @media screen and (min-width: $xl) {
        height: $type__unit*5.75;
      }

      @media screen and (min-width: $xxl) {
        height: $type__unit*6.5;
      }
    }

    .rows__arrow {
      i {
        line-height: $type__unit*3;
      }

      @media screen and (min-width: $xxs) {
        i {
          line-height: $type__unit*4;
        }
      }

      @media screen and (min-width: $xs) {
        i {
          line-height: $type__unit*5;
        }
      }

      @media screen and (min-width: $l) {
        margin-right: $spacing__unit;

        i {
          line-height: $type__unit*6;
        }
      }

      @media screen and (min-width: $xl) {
        i {
          line-height: $type__unit*7;
        }
      }

      @media screen and (min-width: $xxl) {
        i {
          line-height: $type__unit*8;
        }
      }
    }

    .rows__title {
      @extend %type--xxl;
    }

    .collapse {
      &--open {
        .rows__header {
          @media screen and (min-width: $m) {
            height: $type__unit*6.5;
          }

          @media screen and (min-width: $l) {
            height: $type__unit*8;
          }

          @media screen and (min-width: $xl) {
            height: $type__unit*9;
          }

          @media screen and (min-width: $xxl) {
            height: $type__unit*10;
          }
        }
      }
    }
  }

  &--small {
    .rows__arrow {
      i {
        line-height: $type__unit*3;
      }

      @media screen and (min-width: $xs) {
        i {
          line-height: $type__unit*3.5;
        }
      }

      @media screen and (min-width: $l) {
        margin-right: $spacing__unit;
      }
    }

    .rows__title {
      @extend %type--l;
    }
  }

  .collapse {
    &--open {
      .rows__header {
        @include hover(transform, translateY(-3px), '.rows__arrow');
      }
    }
  }
}

.no-touchevents {
  .section__rows {
    &--big {
      .rows__header {
        @media screen and (min-width: $m) {
          &:hover {
            height: $type__unit*6.5;
          }
        }

        @media screen and (min-width: $l) {
          &:hover {
            height: $type__unit*8;
          }
        }

        @media screen and (min-width: $xl) {
          &:hover {
            height: $type__unit*9;
          }
        }

        @media screen and (min-width: $xxl) {
          &:hover {
            height: $type__unit*10;
          }
        }
      }
    }
  }
}

// Grid
.section__card {
  +.section__card {
    margin-top: $spacing__unit;

    @media screen and (min-width: $xs) {
      margin-top: $spacing__unit*2;
    }

    @media screen and (min-width: $xl) {
      margin-top: $spacing__unit*3;
    }
  }
}

.section__card,
.section__grid {

  .card__position,
  .grid__position {
    @extend %grid-gap;
    @include grid;
    @include grid-template-columns(1fr);
    @include grid-child(1, 13, 1, 1);
    grid-row-gap: $spacing__unit*2;

    @media screen and (min-width: $xs) {
      @include grid-template-columns(1fr 1fr);
    }

    @media screen and (min-width: $m) {
      @include grid-child(3, 11, 1, 1);
    }

    @media screen and (min-width: $l) {
      @include grid-template-columns(1fr 1fr 1fr);
      @include grid-child(4, 13, 1, 1);
    }
  }

  .card__element,
  .grid__element {
    &--box {
      .grid__link {
        @include hover(transform, translateX($spacing__unit*0.5), ".grid__header");
        @include hover(transform, translateY(-$spacing__unit*0.25) scale(1.01), ".grid__cover");
        @include hover(color, $black);
      }

      .card__cover,
      .grid__cover {
        @include transition(all $ease__o--sine);
        will-change: transform;

        &+.grid__header {
          margin-top: $spacing__unit*0.5;
        }
      }

      .card__image,
      .cover__image {
        // @include filter(grayscale, 100%);
        @include background-cover;
      }

      .grid__header {
        @include transition(transform $ease__o--sine);
        will-change: transform;

        &+.grid__content {
          margin-top: $spacing__unit*0.5;
        }
      }
    }
  }

  .card__cover,
  .grid__cover {
    .cover__image {
      padding-bottom: 100%;
      background-color: $grey--xl;
      // @include filter(grayscale, 100%);
      @include background-cover;
    }

    &+.card__header,
    &+.grid__header {
      margin-top: $spacing__unit*0.5;

      @media screen and (min-width: $xs) {
        margin-top: $spacing__unit;
      }
    }
  }

  .card__header,
  .grid__header {
    &+.grid__content {
      margin-top: $spacing__unit*0.5;

      @media screen and (min-width: $xs) {
        margin-top: $spacing__unit;
      }
    }
  }

  .card__title,
  .grid__title {
    @extend %type--m;
    font-weight: $bold__unit;
  }

  .card__subtitle,
  .grid__subtitle {
    @extend %type--xxs;
    text-transform: uppercase;
    color: $grey;
  }

  .card__content .grid__content {
    &+.grid__link {
      margin-top: $spacing__unit*0.5;

      @media screen and (min-width: $xs) {
        margin-top: $spacing__unit;
      }
    }
  }

  .card__link {
    margin-top: $spacing__unit*0.25;

    @media screen and (min-width: $xs) {
      margin-top: $spacing__unit*0.5;
    }
  }

  .card__venue {
    padding: $spacing__unit*0.5 0;

    a {
      text-decoration: underline;
    }

    .section__information-label {
      display: none;
    }
  }
}


// Gallery
.section__gallery {
  .tns-inner {
    padding-left: $spacing__unit;

    @media screen and (min-width: $xs) {
      padding-left: $spacing__unit*2;
    }

    @media screen and (min-width: $xl) {
      padding-left: $spacing__unit*3;
    }
  }

  .gallery__wrapper {
    position: relative;
  }

  .gallery__iphone-swiper {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
}

.gallery__slider {
  &:not(.tns-slider) {
    @include flexbox;
    @include flex-wrap(nowrap);
    @include transform(translateX($spacing__unit));

    @media screen and (min-width: $xs) {
      @include transform(translateX($spacing__unit*2));
    }

    @media screen and (min-width: $xl) {
      @include transform(translateX($spacing__unit*3));
    }
  }

  figure {
    margin-right: $spacing__unit;
  }

  img {
    width: auto;
    max-width: none;
    height: 65vw;
    max-height: 480px;
    border-radius: $radius__unit*0;

    @media screen and (min-width: $xxl) {
      max-height: 600px;
    }
  }
}


// Timeline
.section__timeline {
  max-width: 360vw;
  padding-top: $spacing__unit;
  padding-bottom: $spacing__unit;
  box-shadow: 0 12px 18px -18px rgba(0, 0, 0, 0.15), 0 -12px 18px -18px rgba(0, 0, 0, 0.15);
  overflow-x: scroll;
  position: relative;
  @include overflow-iphone;

  &--smooth {
    scroll-behavior: smooth;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .timeline__wrapper {
    width: 360vw;
    padding-right: $spacing__unit;
    position: relative;
  }

  .timeline__helper {
    position: absolute;
    bottom: $spacing__unit;
    left: $spacing__unit;

    .link__button {
      color: $grey;

      i {
        @include animation(breath, 1.2s, infinite, cubic-bezier(0.39, 0.575, 0.565, 1));
      }
    }
  }

  .timeline__header {
    @extend %grid6;
    grid-gap: $spacing__unit*0.25;

    h4 {
      @extend %type--xxs;
      font-weight: $bold__unit;
      padding: 0 $spacing__unit*0.5;
      padding-bottom: $spacing__unit*0.25;
    }
  }

  .timeline__content {
    @extend %grid6;
    grid-gap: $spacing__unit*0.25;
  }

  .timeline__title {
    font-weight: $bold__unit;
    font-size: $type__unit*1.25;
    line-height: $type__unit*1.5;
    height: $type__unit*4.5;
  }

  .timeline__item {
    padding: $spacing__unit*0.5 $spacing__unit*0.5;
    border-radius: $radius__unit*0;

    &--link {
      @include hover(color, $black);
      @include hover(transform, translateY(-$spacing__unit*0.125));
      @include transition(transform $ease__o--sine);
      will-change: transform;

      &.timeline__item--awards {
        @include hover(color, $white);
      }

      .timeline__title {
        @include transition(transform $ease__o--sine);
        will-change: transform;
      }
    }

    &--education-summit {
      @include grid-child(1, 3, 1, 1);
      background-color: $rose;
    }

    &--workshop {
      @include grid-child(3, 4, 1, 1);
      background-color: $violet;
    }

    &--conference {
      @include grid-child(4, 7, 1, 1);
      background-color: $yellow;
    }

    &--local-leaders-retreat {
      @include grid-child(2, 3, 2, 2);
      background-color: $blue;
    }

    &--student-design-charette {
      @include grid-child(3, 7, 2, 2);
      background-color: $green;
    }

    &--awards {
      @include grid-child(6, 7, 4, 4);
      background-color: $black;
      color: $white;
    }

    &--events {
      @include grid-child(3, 7, 3, 3);
      // background-color: rgb(255,206,190);
      background-color: $grey--xl;
    }

    &--opening {
      @include grid-child(3, 4, 4, 4);
      background-color: $grey--xl;
    }

    &--closing {
      @include grid-child(6, 7, 5, 5);
      background-color: $grey--xl;
    }
  }

  @media screen and (min-width: $s) {
    max-width: 180vw;

    .timeline__wrapper {
      width: 180vw;
      padding-right: $spacing__unit*2;
    }

    .timeline__title {
      font-size: $type__unit*1.5;
      line-height: $type__unit*2;
      height: $type__unit*6;
    }
  }

  @media screen and (min-width: $m) {
    max-width: 1680px;
    overflow-x: hidden;
    padding-top: 0;
    padding-bottom: 0;
    box-shadow: none;

    .timeline__wrapper {
      width: auto;
      padding-right: 0;
    }

    .timeline__helper {
      display: none;
    }

    .timeline__header {
      h4 {
        padding: 0 $spacing__unit*1;
      }
    }

    .timeline__item {
      padding: $spacing__unit*0.75 $spacing__unit*1;
    }
  }
}

.section__link {
  a {
    text-decoration: none;
  }
}

// Overlay
.page-overlay {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 8;
  width: 100%;
  z-index: 5;
  @include animation(fadeIn, 0.6s, 1, cubic-bezier(0.445, 0.05, 0.55, 0.95));
  // @include transition(all $ease__io--sine);
  // @include transition-delay(0.2s);
  max-height: 100vh;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  @include overflow-iphone();
  background: white;

  &.page-overlay--fixed-message {
    margin-top: var(--messageBlastHeight)
  }

  &.page-enter,
  &.page-leave-to {
    @include animation(fadeOut, 0.6s, 1, cubic-bezier(0.445, 0.05, 0.55, 0.95));
    // @include opacity(0);
  }

  &.html--scroll-smooth {
    scroll-behavior: smooth;
  }

  .calendar__elements,
  .sharer__elements {
    padding: $type__unit*0.75 $type__unit*1.5 $type__unit*0.75 $type__unit*5;
    left: -10px;
    right: auto;

    a,
    span {
      color: $black;
    }
  }

  .calendar__elements {
    left: 40px;
    width: 190px;
  }

  &--education-summit {
    background-color: $rose;

    .calendar__elements,
    .sharer__elements {
      background: $rose;
    }

    .information__soldout {
      color: $rose;
    }
  }

  &--workshop {
    background-color: $violet;

    .calendar__elements,
    .sharer__elements {
      background: $violet;
    }

    .information__soldout {
      color: $violet;
    }
  }

  &--conference {
    background-color: $yellow;

    .calendar__elements,
    .sharer__elements {
      background: $yellow;
    }

    .information__soldout {
      color: $yellow;
    }
  }

  &--satellite {
    background-color: $grey--xl;

    .calendar__elements,
    .sharer__elements {
      background: $grey--xl;
    }

    .information__soldout {
      color: $grey--xl;
    }
  }

  &--local-leaders-retreat {
    background-color: $blue;

    .calendar__elements,
    .sharer__elements {
      background: $blue;
    }

    .information__soldout {
      color: $blue;
    }
  }

  &--student-design-charette {
    background-color: $green;

    .calendar__elements,
    .sharer__elements {
      background: $green;
    }

    .information__soldout {
      color: $green;
    }
  }

  &--awards {
    background-color: $black;
    color: $white;

    .section__label {
      color: $grey !important;
    }

    .section__content {
      .section__link {
        .sharing__trigger {
          @include background-image('share--white');

          &--active {
            @include background-image('share--black');
          }
        }
      }
    }

    .link__button {
      @include hover(color, $white);

      i {
        @include background-image('link--white');
      }
    }

    a {
      color: $white;
    }
  }
}

// Event Brite
.eventbrite__wrapper {
  max-width: 1080px;
  margin: 0 auto;
  max-height: calc(100vh - 170px);

  @media screen and (max-width: $m) {
    height: 100vh !important;
  }

  @media screen and (min-width: $m) {
    max-height: none;
  }
}

.buy-tickets {
  .footer {
    @media screen and (max-width: $m) {
      display: none;
    }
  }

  main {
    @media screen and (max-width: $m) {
      padding-bottom: $spacing__unit*3;
    }
  }
}

// Embedded Video

.video-embedded__wrapper {
  position: relative;
  padding-bottom: 56.25%; // Fallback
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}