// Archive
@use "sass:math";

.archive__header {
  .section {
    &--first {
      padding-bottom: 0;
    }
  }
}

.section__thumbnails {
  .thumbnails__position {
    @extend %grid-gap;
    @include grid;
    @include grid-template-columns(1fr);
    @include grid-child(1,13,1,1);
    grid-row-gap: $spacing__unit*2;
    @media screen and (min-width: $xs) {
      @include grid-template-columns(repeat(2, 1fr));
    }
    @media screen and (min-width: $m) {
      @include grid-template-columns(repeat(3, 1fr));
    }
    @media screen and (min-width: $l) {
      @include grid-template-columns(repeat(4, 1fr));
    }
  }
  &--talk,
  &--workshop {
    .thumbnails__position {
      @media screen and (min-width: $s) {
        @include grid-template-columns(repeat(3, 1fr));
      }
      @media screen and (min-width: $m) {
        @include grid-template-columns(repeat(4, 1fr));
      }
      @media screen and (min-width: $xl) {
        @include grid-template-columns(repeat(6, 1fr));
      }
    }
    .thumbnail__title {
      @extend %type--m;
    }
  }
}

.thumbnail__link {
  @include hover(color, $black);
  @include hover(transform, translateX($spacing__unit*0.5), ".thumbnail__header");
  @include hover(transform, translateY(-$spacing__unit*0.25) scale(1.01), ".thumbnail__cover");
  .link__button {
    @extend %type--xl;
    i {
      line-height: $type__unit*3.5;
      height: $type__unit*3.5;
      width: $type__unit*3.5;
    }
  }
}

.thumbnail__cover {
  @include transition(transform $ease__o--sine);
  will-change: transform;
  width: 100%;
  .cover__image {
    padding-bottom: 100%;
  }
}

.thumbnail__header {
  @include transition(transform $ease__o--sine);
  will-change: transform;
  margin-top: $spacing__unit*0.5;
}

.thumbnail__label {
  @extend %type--xxs;
  color: $grey;
  text-transform: uppercase;
}

.thumbnail__title {
  @extend %type--l;
}


// Basic page and speakers
.page--basic-page,
.speakers,
.program,
.program-slug {
  .section__header {
    // .section__titling {
    //   grid-row-gap: $spacing__unit/4;
    // }
    .section__label {
      color: $basic__color--active;
    }
    .section__subtitle {
      @media screen and (min-width: $xs) {
        @include grid-child(1,13,auto,auto);
      }
      @media screen and (min-width: $s) {
        @include grid-child(2,13,auto,auto);
      }
      @media screen and (min-width: $l) {
        @include grid-child(3,11,auto,auto);
      }
      .speakers__anchor {
        @extend %type--s;
        text-transform: uppercase;
        padding-left: math.div($spacing__unit, 1.5);
      }
    }
  }
  .section__content {
    .content__wrapper {
      & > div {
        &:nth-child(odd),
        &:nth-child(even) {
          &.section__side {
            @include grid-child(1,13,auto,auto);
            @media screen and (min-width: $s) {
              @include grid-child(2,12,auto,auto);
            }
            @media screen and (min-width: $m) {
              @include grid-child(1,4,auto,auto);
            }
            @media screen and (min-width: $l) {
              @include grid-child(2,4,auto,auto);
            }
          }
        }
      }
      // .section__content .content__wrapper > {
      //   .section__rows{
      //     display: block;
      //     @include grid-child(4, 9, auto, auto)
      //   }
      //   .rows__background,
      //   .rows__wrapper {
      //     display: block;
      //   }
      // }
      .section__cards,
      .section__rows,
      .section__paragraph {
        &:nth-child(odd),
        &:nth-child(even) {
          @include grid-child(1,13,auto,auto);
          @media screen and (min-width: $s) {
            @include grid-child(2,12,auto,auto);
            font-size: 21px;
            line-height: 36px;
          }
          @media screen and (min-width: $m) {
            @include grid-child(4,12,auto,auto);
          }
          @media screen and (min-width: $l) {
            @include grid-child(4,9,auto,auto);
          }
        }
      }
      .section__cards {
        &:nth-child(odd),
        &:nth-child(even) {
          @media screen and (min-width: $l) {
            @include grid-child(4,13,auto,auto);
          }
        }
      }
      .section__paragraph {
        h2, h3, h4, h5 {
          @extend %type--l;
        }
      }
      .section__rows {
        padding: 0;
        margin: 0;
        margin-top: $spacing__unit;
        h2 {
          @extend %type--xl;
          font-weight: $bold__unit;
        }
        h3, h4, h5 {
          @extend %type--m;
        }
      }
      .rows__background,
      .rows__wrapper,
      .rows__content {
        display: block;
      }
      .rows__wrapper {
        margin-top: $spacing__unit;
      }
    }
  }
}

.program--prev-page-speakers {
  > * {
    &:not(.page-overlay) {
      @include opacity(0);
    }
  }
}

.speakers {
  .section__content {
    margin-top: $spacing__unit;
  }
}

.program,
.program-slug {
  .section__content {
    .section__side {
      @extend %type--xs;
      @extend %grid2;
      @media screen and (min-width: $m) {
        display: block;
      }
    }
    .section__information {
      @media screen and (min-width: $m) {
        @include grid-child(1,3,1,1);
      }
    }
    .section__link {
      margin-top: $spacing__unit;
      position: relative;
      .sharing__trigger {
        @include background-image('share--black');
      }
      .calendar__trigger {
        @include background-image('calendar--black');
        background-size: 80%;
      }
      & + .section__host {
        margin-top: $spacing__unit*2;
      }
    }
    .section__host {
      @media screen and (min-width: $m) {
        @include grid-child(1,3,auto,auto);
      }
      figure {
        margin-top: $spacing__unit*0.5;
        img {
          max-width: $spacing__unit*3;
          max-height: $spacing__unit*3;
          @media screen and (min-width: $m) {
            max-width: $spacing__unit*4;
            max-height: $spacing__unit*4;
          }
        }
      }
    }
  }
}

.speakers__background {
  & + .speakers__wrapper {
    @extend %section-spacing;
  }
}

.related__background {
  & + .related__wrapper {
    @extend %section-spacing;
  }
}

.speakers__item {
  @include grid-template-rows(auto 1fr);
  margin-bottom: $spacing__unit*2;
  grid-row-gap: $spacing__unit*0.5;
  @media screen and (min-width: $xxl) {
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.related__item {
  @include hover(color, $black, "a");
  @include hover(transform, translateX($spacing__unit*0.5), ".related__header");
  @include hover(transform, translateY(-$spacing__unit*0.25) scale(1.01), ".related__cover");
  @include grid-child(auto,span 12,auto,auto);
  @media screen and (min-width: $xxs) {
    @include grid-child(auto,span 6,auto,auto);
  }
  @media screen and (min-width: $m) {
    @include grid-child(auto,span 3,auto,auto);
  }
}

.speakers__cover,
.speakers__link {
  @include grid-child(1,13,auto,auto);
  @media screen and (min-width: $xs) {
    @include grid-child(1,5,auto,auto);
  }
  @media screen and (min-width: $m) {
    @include grid-child(1,4,auto,auto);
  }
  @media screen and (min-width: $l) {
    @include grid-child(2,4,auto,auto);
  }
}

.speakers__cover,
.thumbnail__cover,
.related__cover {
  .cover__image,
  .cover__letters {
    padding-bottom: 100%;
  }
  .cover__letters {
    background-color: $black;
    color: $white;
    position: relative;
  }
  .cover__placeholder {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    @extend %type--xl;
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
  }
  .cover__image {
    @include background-cover;
    // @include filter(grayscale, 1);
  }
}

.related__cover {
  @include transition(transform $ease__o--sine);
  will-change: transform;
}

.related__header {
  @include transition(transform $ease__o--sine);
  will-change: transform;
  padding-right: $spacing__unit*0.5;
  @media screen and (min-width: $xl) {
    padding-right: $spacing__unit;
  }
  @media screen and (min-width: $xxl) {
    padding-right: $spacing__unit*2;
  }
  .related__date {
    @extend %type--xxs;
    text-transform: uppercase;
    margin-top: $spacing__unit*0.5;
  }
  .related__title {
    margin-top: $spacing__unit*0.5;
  }
  .related__subtitle {
    font-weight: $bold__unit;
  }
}

.speakers__content {
  @include grid-child(1,13,auto,auto);
  @media screen and (min-width: $xs) {
    @include grid-child(5,13,auto,span 2);
  }
  @media screen and (min-width: $m) {
    @include grid-child(4,11,auto,span 2);
  }
  @media screen and (min-width: $l) {
    @include grid-child(4,10,auto,span 2);
  }
  @media screen and (min-width: $xl) {
    @include grid-child(4,9,auto,span 2);
  }
}

.speakers__name {
  font-weight: $bold__unit;
}


// Calendar

.section__calendar {
  margin-top: $spacing__unit;
}

.calendar__wrapper {
  border-top: 1px solid $grey--xl;
}

.calendar__item  {
  @extend %type--xs;
  padding: $spacing__unit 0;
  grid-row-gap: 0;
  border-bottom: 1px solid $grey--xl;
  &:last-child {
    border-bottom: none;
  }
  &--conference {
    .type__label,
    .type__bubble {
      background: $yellow;
    }
  }
  &--workshop {
    .type__label,
    .type__bubble {
      background: $violet;
    }
  }
  &--satellite {
    .type__label,
    .type__bubble {
      background: $grey--xl;
    }
  }
  &--awards {
    .type__label,
    .type__bubble {
      background: $black;
      color: $white;
    }
  }
  &--education-summit {
    .type__label,
    .type__bubble {
      background: $rose;
    }
  }
  &--local-leaders-retreat {
    .type__label,
    .type__bubble {
      background: $blue;
    }
  }
  &--student-design-charette {
    .type__label,
    .type__bubble {
      background: $green;
    }
  }
  &--nolink {
    .calendar__header {
      color: $grey;
    }
  }
}

.calendar__meta {
  @include flexbox;
  @include grid-child(1,12,1,1);
  position: relative;
  min-height: $type__unit*2;
  @media screen and (min-width: $l) {
    @include grid-child(1,5,auto,auto);
  }
  .type__bubble {
    height: $type__unit;
    width: $type__unit;
    border-radius: $type__unit;
    margin-right: $basic__unit*3;
    margin-top: $basic__unit*2;
    @media screen and (min-width: $s) {
      display: none;
    }
  }
  .time__label {
    font-weight: $bold__unit;
    color: $grey;
    margin-right: 0;
    min-width: $spacing__unit*4;
    @media screen and (min-width: $s) {
      min-width: $spacing__unit*4.5;
      margin-right: $spacing__unit;
    }
  }
  .type__label {
    @extend %type--xxs;
    text-transform: uppercase;
    text-align: center;
    font-weight: $bold__unit;
    border-radius: $type__unit*1.25;
    padding: $basic__unit $basic__unit*4;
    max-height: $spacing__unit*1.25;
    max-width: $spacing__unit*6;
    display: none;
    @media screen and (min-width: $s) {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    @media screen and (min-width: $m) {
      max-width: $spacing__unit*9;
    }
    @media screen and (min-width: $l) {
      max-width: none;
    }
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.breakout__header {
  @include grid-child(1,12,auto,auto);
  @media screen and (min-width: $l) {
    @include grid-child(5,10,auto,auto);
  }
}

.calendar__header {
  @include grid-child(1,12,3,3);
  @media screen and (min-width: $l) {
    @include grid-child(5,10,auto,auto);
  }
}

.calendar__venue {
  color: $grey;
  @include grid-child(1,12,2,2);
  @media screen and (min-width: $l) {
    @include grid-child(10,12,auto,auto);
  }
  .foo {
    color: $grey;
  }
}

.calendar__header {
  a {
    @include hover(transform, translateX($spacing__unit*0.5), 'h3');
    @include hover(color, $black);
    @include transition(all $ease__io--sine--f);
    will-change: transform;
    display: block;
    max-width: 600px;
    font-size: $type__unit*1.5;
    line-height: $type__unit*2.25;
    span {
      display: block;
    }
  }
  h3 {
    @include transition(transform $ease__io--sine--f);
    will-change: transform;
  }
}

.calendar__items--live-program {
  .calendar__meta {
    @media screen and (min-width: $s) {
      @include grid-child(1,5, auto, auto);
    }
    @media screen and (min-width: $l) {
      @include grid-child(1,4, auto, auto);
    }
  }
  .calendar__header {
    @media screen and (min-width: $s) {
      @include grid-child(5,11,auto,auto);
    }
    @media screen and (min-width: $l) {
      @include grid-child(4,10,auto,auto);
    }
    a {
      span {
        display: inline;
      }
    }
  }
  .calendar__venue {
    @media screen and (min-width: $s) {
      @include grid-child(11,13,auto,auto);
    }
    @media screen and (min-width: $l) {
      @include grid-child(10,13,auto,auto);
    }
  }
}

.breakout__header {
  @extend %type--m;
}

.calendar__tools {
  display: none;
  position: relative;
  @media screen and (min-width: $s) {
    @include grid-child(12,13,auto,auto);
    @include flexbox;
    @include justify-content(flex-end);
  }
}

.sharing__trigger,
.calendar__trigger {
  display: inline-block;
  @include background-contain;
  width: $type__unit*2;
  height: $type__unit*2;
  @media screen and (min-width: $s) {
    width: $type__unit*2.5;
    height: $type__unit*2.5;
  }
  &--active {
    position: relative;
    z-index: 3;
  }
}

.sharing__trigger {
  @include background-image('share');
}

.calendar__trigger {
  @include background-image('calendar');
  margin-left: $spacing__unit;
}

.breakout__item {
  grid-row-gap: 0;
  margin-bottom: $basic__unit*4;
  .calendar__tools {
    margin-bottom: $basic__unit*2;
  }
  @media screen and (min-width: $s) {
    margin-bottom: $basic__unit*3;
  }
  .sharing__trigger,
  .calendar__trigger {
    width: $type__unit*1.75;
    width: $type__unit*1.75;
    @media screen and (min-width: $s) {
      width: $type__unit*2;
      height: $type__unit*2;
    }
  }
}

.calendar__breakout,
.breakout__list {
  @include grid-child(1,13,auto,auto);
}

.breakout__wrapper {
  grid-row-gap: $type__unit;
  margin-top: $spacing__unit;
}

.calendar__overview {
  grid-gap: $type__unit;
}

.overview__item {
  @include grid-child(1, 13, auto, auto);
  @media screen and (min-width: $xs) {
    @include grid-child(auto, span 6, auto, auto);
  }
  @media screen and (min-width: $s) {
    @include grid-child(auto, span 4, auto, auto);
  }
  @media screen and (min-width: $l) {
    @include grid-child(auto, span 2, auto, auto);
  }
}

.overview__header {
  h3 {
    padding-left: $type__unit*2;
  }
}

.overview__list {
  margin-top: $spacing__unit*0.5;
}

.overview__element {
  @extend %type--xxs;
  margin-top: $spacing__unit*0.5;
  border-radius: $spacing__unit;
  padding: $type__unit $type__unit*2;
  @include transition(all $ease__io--sine--f);
  @include hover(color, $black, 'a');
  @include hover(border-radius, $spacing__unit*0.5);
  &--other {
    border: 1px solid $grey;
  }
  &--education-summit {
    background: $rose;
  }
  &--conference {
    background: $yellow;
  }
  &--workshop {
    background: $violet;
  }
  &--local-leaders-retreat {
    background: $blue;
  }
  &--student-design-charette {
    background: $green;
  }
  &--satellite {
    background: $grey--xl;
  }
  &--awards {
    @include hover(color, $white, 'a');
    background: $black;
    color: $white;
    a {
      color: $white;
    }
  }
}

.overview__name {
  font-weight: $bold__unit;
}

.overview__light {
  font-weight: $light__unit;
}

.information__soldout {
  @extend %type--xxs;
  text-transform: uppercase;
  display: table;
  font-weight: $bold__unit;
  padding: 0 $spacing__unit*0.5;
  border-radius: $spacing__unit*0.5;
  background: $black;
  color: $white;
  margin-bottom: $spacing__unit*0.5;
}
