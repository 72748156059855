@import '00_variables';
@import '01_functions';
@import '02_reset';
@import '03_components';
@import '04_theme';
@import '05_partial';
@import '06_archives';
@import '07_templates';
@import '08_animations';

.TEST {
	display: none;
}


.gallery__wrapper .gallery__slider.tns-slider {
  display: flex;
}

body .gallery__wrapper {
  overflow-x: scroll;
}

.tns-controls,
.tns-liveregion {
  display: none;
}
